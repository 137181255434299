import { TypeUserParticipant } from '@/redux/slices/participants/interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface TypeStateApollo {
  user: string;
  agent: string;
  agent_id: string;
  idMongoTo: TypeUserParticipant;
  showDots: boolean;
}

const initialState: TypeStateApollo = {
  user: '',
  agent: '',
  agent_id: '',
  idMongoTo: {} as TypeUserParticipant,
  showDots: false
};

export const apolloSlice = createSlice({
  name: 'apollo',
  initialState,
  reducers: {
    setAgentId: (state, action: PayloadAction<string>) => {
      if (action.payload !== state.agent_id) {
        state.agent_id = action.payload;
      }
    },
    setShowDots(state, action) {
      state.showDots = action.payload;
    }
  }
});

export const { setAgentId, setShowDots } = apolloSlice.actions;

export default apolloSlice.reducer;
