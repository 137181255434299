import React, { useEffect, useState } from 'react';
import { HStack, Menu, MenuItem, Spinner } from '@gluestack-ui/themed';
import { Pressable } from '@gluestack-ui/themed';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { DotsVertical, Pencil, Trash } from '@/assets/icons/Icons';
import { MenuItemLabel } from '@gluestack-ui/themed';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { deleteLabel } from '@/redux/slices/labels/requests';
import { useLinkss } from '@/providers/LinkProvider';
import translate from '@/helpers/traslations';
import MainCta from '../MainCta';
import { Label } from '@/redux/slices/labels/interfaces';
import { Text } from '@gluestack-ui/themed';
import { useCollapsed } from '@/providers/CollpasedProvider';
import { isArray } from '@/utils/functions';

const MenuDots = ({
  display,
  openMenu,
  onCloseMenu,
  onHoverInDots,
  onHoverOutDots,
  setOpenMenu,
  color,
  id
}: {
  display: boolean;
  openMenu: boolean;
  onCloseMenu: () => void;
  onHoverInDots: () => void;
  onHoverOutDots: () => void;
  setOpenMenu: (value: boolean) => void;
  color: string;
  id: number;
}) => {
  const dispatch = useAppDispatch();
  const { showToast, handleCopyCode } = useLinkss();
  const { hableModalLabel } = useCollapsed();
  const labels = useAppSelector((state) => state.labels.labels);
  const [currentLabel, setCurrentLabel] = useState<Label>();
  const [loading, setLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    if (labels) {
      setCurrentLabel(labels.find((label: Label) => label.id == id));
    }
  }, [labels]);

  const handleDeleteLabel = (id: number) => {
    setLoading(true);
    dispatch(deleteLabel(id))
      .then((res) => {
        onCloseMenu();
        setLoading(false);
        setDialogVisible(false);
        showToast({
          title: translate('success'),
          subtitle: translate('messages.labelDeleted'),
          variant: 'accent',
          type: 'success'
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        if (isArray(err?.message)) {
          err?.message.forEach((error: string) => {
            showToast({
              type: 'error',
              title: `ERROR: ${err?.transactionId}`,
              subtitle: error,
              variant: 'accent',
              cta: (
                <MainCta
                  labelId={'actions.copy'}
                  uppercase
                  variant="outline"
                  onPress={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handleCopyCode(err?.transactionId);
                  }}
                />
              )
            });
          });
          return;
        }
        showToast({
          type: 'error',
          title: 'Error',
          subtitle: err.message,
          variant: 'accent'
        });
      });
  };

  const handleCancelDeleteLabel = () => {
    setDialogVisible(false);
  };

  const onCloeMenuLabel = () => {
    setDialogVisible(false);
    onCloseMenu();
  };

  return (
    display && (
      <Menu
        isOpen={openMenu}
        onClose={() => onCloeMenuLabel()}
        trigger={({ ...triggerProps }) => (
          <Pressable
            {...triggerProps}
            onHoverIn={() => onHoverInDots()}
            onHoverOut={() => onHoverOutDots()}
            onPress={() => setOpenMenu(true)}
            sx={{
              width: 25,
              height: 25,
              borderRadius: '$full',
              alignItems: 'center',
              justifyContent: 'center',
              ':hover': { backgroundColor: '$backgroundActive' }
            }}
          >
            <MaterialCommunity size="sm" icon={DotsVertical} color={color ?? color} />
          </Pressable>
        )}
      >
        <MenuItem
          gap="$2"
          onPress={() => hableModalLabel({ label: currentLabel })}
          textValue="close"
          key="close"
        >
          <MaterialCommunity size="sm" icon={Pencil} color={color ?? color} />
          <MenuItemLabel>{translate('actions.edit')}</MenuItemLabel>
        </MenuItem>
        <MenuItem
          gap="$2"
          onPress={() => setDialogVisible(true)}
          textValue="delete-label"
          key="delete-label"
          closeOnSelect={false}
        >
          {dialogVisible ? (
            <HStack alignItems="center" space="xs">
              <Text>{translate('youAreSure')}</Text>
              <MainCta
                buttonSize="xs"
                labelId={'no'}
                uppercase
                variant="outline"
                onPress={() => handleCancelDeleteLabel()}
              />
              <MainCta
                buttonSize="xs"
                labelId={!loading && 'yes'}
                uppercase
                loading={loading}
                disabled={loading}
                onPress={() => handleDeleteLabel(currentLabel?.id)}
              />
            </HStack>
          ) : (
            <HStack alignItems="center" space="xs">
              <MaterialCommunity size="sm" icon={Trash} color={color ?? color} />
              <MenuItemLabel>{translate('actions.delete')}</MenuItemLabel>
            </HStack>
          )}
        </MenuItem>
      </Menu>
    )
  );
};

export default React.memo(MenuDots);
