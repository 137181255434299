import React, { createContext, useCallback, useContext, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { filtersToCheckOutConver } from '@/utils/filters';
import { setOutConversationToFilterQueue } from '@/redux/slices/conversation';
import { Message } from '@/types/message';

import { Contacts } from '@/types/typesRedux/interfaces';
import { newMessage } from '@/redux/slices/messages';
import { createMessage } from '@/redux/slices/messages/requests';
import { OriginOfMessage } from '@/types/enums/message';
import { PreviewFilesInterface } from '@/components/conversation/footer/interfaces.d';
import { ConversationContextType } from './interfaces';
import { setSendingTemplates } from '@/redux/slices/templates';
import { File, Template } from '../types';
import { replaceDinamicLinks } from '@/redux/slices/templates/requests';
import translate from '@/helpers/traslations';
import MainCta from '@/atoms/MainCta';
import { useLinkss } from './LinkProvider';
import { setConversationInputValue, setModalMore } from '@/redux/slices/theme';
import { PlatformsEnum } from '@/types/enums/conversation';
import { noAddUrl } from '@/utils/constants';
import { generateUUID } from '@/utils/functions';

const ConversationContext = createContext<ConversationContextType | undefined>(undefined);

const useConversation = (): ConversationContextType => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error('useConversation must be used within a ConversationProvider');
  }
  return context;
};

const ConversationProvider = ({ children }) => {
  const user = useAppSelector((state: RootState) => state.user.user);
  const teamId = useAppSelector((state: RootState) => state.user.team?.id);
  const companyId = useAppSelector((state: RootState) => state.user.company.IDEmpresas);
  const converId = useAppSelector((state: RootState) => state.conversations.id_conversation);
  const dispatch = useAppDispatch();

  const [joinEventQueue, setJoinEventQueue] = useState<boolean>(false);
  const [fragmentId, setFragmentId] = useState<string>('');
  const [idMongoTo, setIdMongoTo] = useState<Contacts>();
  const [dataBc, setDataBc] = React.useState<{ question: string; answer: string }>({
    question: '',
    answer: ''
  });
  const [errorToUploadFile, setErrorToUploadFile] = useState(false);
  const [previewFiles, setPreviewFiles] = useState<PreviewFilesInterface[]>([]);
  const [previewVideos, setPreviewVideos] = useState<any[]>([]);
  const [contact, setContact] = useState<Contacts>();

  const [attachments, setAttachments] = useState<File>(null);

  const { showToast, handleCopyCode } = useLinkss();

  const handleSendMessage = useCallback(
    async ({
      data,
      filter,
      conversation_id,
      addToRedux = true,
      platform,
      messagerbir_conversation
    }): Promise<Message | null> => {
      return new Promise((resolve, reject) => {
        try {
          const putInQueue = filtersToCheckOutConver.includes(filter);

          if (joinEventQueue && filter && conversation_id && putInQueue) {
            dispatch(
              setOutConversationToFilterQueue({
                conversationId: conversation_id,
                filter: filter,
                teamId
              })
            );
          }

          if (data.message) {
            const messageToSend: Message = {
              id_message: generateUUID(),
              id_conversation: conversation_id,
              id_fragment: fragmentId,
              content: data.message,
              company_id: Number(companyId),
              status: 'pending',
              direction: 'sent',
              deleted: false,
              file: data?.file || null, // Usar 'null' en lugar de 'file && file'
              replyTo: data?.reply || null, // Usar 'null' en lugar de 'reply && reply'
              from: {
                _id: user?.idMongoUser,
                id_user: user?.id,
                company_id: Number(companyId),
                name: user?.name,
                email: user?.email,
                avatar: user?.avatar,
                type_user: user?.type_user,
                pxsol: user?.isPxsol
              },
              to: idMongoTo,
              type: data?.type,
              origin: OriginOfMessage?.internal,
              platform: platform,
              id_messagerbir: messagerbir_conversation ?? null
            };
            if (addToRedux) {
              dispatch(newMessage(messageToSend));
            }

            dispatch(setConversationInputValue({ conversationId: converId, inputValue: '' }));
            dispatch(createMessage({ message: messageToSend }))
              .then(() => {
                resolve(messageToSend);
              })
              .catch((error) => reject(error));
          } else {
            // Si no hay mensaje para enviar, resuelve la promesa con null o un mensaje adecuado.
            resolve(null);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    [fragmentId, user, companyId, idMongoTo, converId, dispatch]
  );

  const onSelectTemplateResponse = useCallback(
    ({ template, callback, platform }: { template: Template; callback: any; platform: string }) => {
      dispatch(setSendingTemplates({ templateId: template?.id, status: true }));
      dispatch(
        replaceDinamicLinks({
          fragmentId,
          templateId: template?.id,
          metadata: { name: contact[0] }
        })
      )
        .then((response) => {
          dispatch(
            setConversationInputValue({
              conversationId: converId,
              inputValue: response?.data?.trim()
            })
          );
          if (template?.attachments && template?.attachments.length > 0) {
            template?.attachments?.forEach((attachment) => {
              console.log({ attachment });

              if (attachment?.file) {
                if (
                  platform !== PlatformsEnum.whatsapp &&
                  !noAddUrl.includes(attachment?.file?.type)
                ) {
                  dispatch(
                    setConversationInputValue({
                      conversationId: converId,
                      inputValue:
                        response?.data.trim() + '\n \n' + '*Adjunto:* ' + attachment?.file?.url
                    })
                  );
                }
                setAttachments(attachment?.file);
              }
            });
          }
          callback();
          dispatch(setModalMore(false));
          dispatch(setSendingTemplates({ templateId: template?.id, status: false }));
        })
        .catch((error) => {
          console.log({ error });
          dispatch(setSendingTemplates({ templateId: template?.id, status: false }));
          showToast({
            title: translate('error'),
            subtitle: translate('messageError.errorToUpdateStatus'),
            type: 'error',
            cta: error?.transactionId && (
              <MainCta
                labelId={'actions.copy'}
                uppercase
                variant="outline"
                onPress={(e: { preventDefault: () => void }) => {
                  e.preventDefault();
                  handleCopyCode({ text: error?.transactionId });
                }}
              />
            )
          });
          callback();
          dispatch(setModalMore(false));
        });
    },
    [fragmentId, user, companyId, idMongoTo, converId, dispatch]
  );

  const contextValue = React.useMemo(
    () => ({
      setDataBc,
      dataBc,
      errorToUploadFile,
      setErrorToUploadFile,
      previewFiles,
      setPreviewFiles,
      setFragmentId,
      setIdMongoTo,
      idMongoTo,
      joinEventQueue,
      setJoinEventQueue,
      handleSendMessage,
      fragmentId,
      previewVideos,
      setPreviewVideos,
      contact,
      setContact,
      onSelectTemplateResponse,
      attachments,
      setAttachments
    }),
    [
      setDataBc,
      dataBc,
      errorToUploadFile,
      setErrorToUploadFile,
      previewFiles,
      setPreviewFiles,
      setFragmentId,
      setIdMongoTo,
      idMongoTo,
      joinEventQueue,
      setJoinEventQueue,
      handleSendMessage,
      fragmentId,
      previewVideos,
      setPreviewVideos,
      onSelectTemplateResponse,
      attachments,
      setAttachments
    ]
  );

  return (
    <ConversationContext.Provider value={contextValue}>{children}</ConversationContext.Provider>
  );
};

export { ConversationProvider, useConversation };
