import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ABIERTOS, ALL, GRUPOS, SIN_ASIGNAR, TODOS } from '@/utils/filters';
import {
  addConversationToFilter,
  cleanStateConversation,
  clearOutConversationToStateById,
  outConversationToFilter,
  removeConversationFromFilter,
  setFilterState,
  setFilterStateAndUnreadCount,
  setFragments,
  setNewConversationToState,
  setPagesOfFilters,
  setParticipants,
  setProgress,
  setSections,
  setUnreadCountState,
  setUnreadCountToConversation,
  setWaitingForResponse,
  updateAnyPropsOfOneConversations
} from '../../actionTypes/index';

import { Thunk } from '@/redux/store';

import { Conversation } from '../../../types/conversation';
import axiosInstance from '../../../utils/network/instanceAxios';
import {
  compareConversation,
  expiredToken,
  orderConversationsByUpdatedAt,
  setConversations,
  setErrorToGetConversations,
  setIdConversation,
  setNewConversation,
  setNewGroup,
  updateUpdateAtConversation
} from '../../actionTypes';
import {
  ProsCreateGroup,
  PropsChangeNameGroup,
  UpdateConverStatus,
  conversationState,
  PropsCreateConversation,
  FilterTypeConversation
} from './interfaces';
import { setNewFragment } from '../fragments';
import { OriginOfMessage } from '@/types/enums/message';
import { IQuery } from '../filters/interfaces';
import { ETypesSections } from '@/routers/MainRouter';

const initialState: conversationState = {
  conversations: {},
  error: {
    status: false,
    msg: ''
  },
  filterConversations: {},
  numberOfConversations: {},
  loading: false,
  loadingGetAllFilter: false,
  conversation: {} as Conversation,
  id_conversation: '',
  newGroup: false,
  pagesOfFilters: {},
  outConversationToFilterQueue: {},
  waitingForResponse: false,
  unread: {}
};

const conversationSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setLoadingGetAllFilter(state, action: PayloadAction<boolean>) {
      state.loadingGetAllFilter = action.payload;
    },
    cleanDataFilterConversations(state, action) {
      const { teamId } = action.payload;
      if (state.filterConversations && state.filterConversations.clients) {
        Object.keys(state.filterConversations.clients).forEach((filter) => {
          state.filterConversations[teamId].clients[filter] = {
            conversations: [],
            conversationCountWithOpenFragment: 0,
            countValidateUnread: false
          };
        });
      }
    },
    setOutConversationToFilterQueue: (state, action) => {
      state.outConversationToFilterQueue[action.payload.conversationId] = {
        conversationId: action.payload.conversationId,
        filter: action.payload.filter,
        teamId: action.payload.teamId
      };
    },
    setAddLabelsInConversation: (state, action) => {
      const { conversationId, label } = action.payload;
      if (!state.conversations[conversationId].tags.includes(label)) {
        state.conversations[conversationId].tags.push(label);
      } else {
        state.conversations[conversationId].tags = state.conversations[conversationId].tags.filter(
          (item) => item !== label
        );
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setConversations, (state, action: PayloadAction<any>) => {
      state.conversations = {
        ...state.conversations,
        ...action.payload.conversations
      };
    });
    builder.addCase(setNewConversationToState, (state, action: PayloadAction<any>) => {
      state.conversations[action.payload?._id] = action.payload;
    });
    builder.addCase(setWaitingForResponse, (state, action: PayloadAction<boolean>) => {
      state.waitingForResponse = action.payload;
    });
    builder.addCase(setFilterState, (state, action) => {
      const { type, filter, count, teamId } = action.payload;

      // Verifica que el objeto para `teamId` exista
      if (!state.filterConversations[teamId]) {
        state.filterConversations[teamId] = {}; // Inicializa el objeto para `teamId`
      }

      // Verifica que el objeto para `type` exista dentro de `teamId`
      if (!state.filterConversations[teamId][type]) {
        state.filterConversations[teamId][type] = {}; // Inicializa el objeto para `type`
      }

      // Finalmente, establece el valor del filtro
      state.filterConversations[teamId][type][filter] = count[filter];
    });

    builder.addCase(setFilterStateAndUnreadCount, (state, action) => {
      const { type, filter, count, teamId } = action.payload;
      const data = count?.[filter];

      if (data) {
        // Asegúrate de que el tipo y el filtro estén inicializados
        if (!state.filterConversations?.[teamId]?.[type]) {
          state.filterConversations[teamId][type] = {};
        }
        if (!state.filterConversations?.[teamId]?.[type]?.[filter]) {
          state.filterConversations[teamId][type][filter] = {
            conversations: [],
            conversationCountWithOpenFragment: 0,
            countValidateUnread: false
          };
        }

        const filterState = state.filterConversations[teamId]?.[type]?.[filter];

        // Añadir nuevas conversaciones, evitando duplicados
        const newConversations = data.conversations.filter(
          (c) => !filterState.conversations.some((existingC) => existingC.id === c.id)
        );
        filterState.conversations.push(...newConversations);
        // Actualizar el conteo de conversaciones con fragmentos abiertos
        if (typeof data.conversationCountWithOpenFragment === 'number') {
          filterState.conversationCountWithOpenFragment = data.conversationCountWithOpenFragment;
        }

        // Si 'dataValidateUnread' está definido y es verdadero, actualizar el estado
        if (data.countValidateUnread) {
          filterState.countValidateUnread = data.countValidateUnread;
        }
      }
    });

    builder.addCase(setUnreadCountToConversation, (state, action) => {
      const { conversationId, count } = action.payload;
      state.unread[conversationId] = count;
    });
    builder.addCase(setUnreadCountState, (state, action) => {
      state.unread = { ...state.unread, ...action.payload };
    });
    builder.addCase(setIdConversation, (state, action) => {
      state.id_conversation = action.payload;
    });

    builder.addCase(updateUpdateAtConversation, (state, action) => {
      // Obtiene todas las claves de los filtros
      const { type: conversationType, conversationId, teamId } = action.payload;
      // Obtiene todas las claves de los filtros para el tipo de conversación específico
      const filterKeys = Object.keys(state.filterConversations[teamId][conversationType]);
      // Itera a través de cada filtro
      filterKeys.forEach((filterKey) => {
        // Conversaciones para el filtro actual
        state.filterConversations[teamId][conversationType][filterKey].conversations.find(
          (conver) => {
            if (conver.id === conversationId) {
              conver.updatedAt = new Date().toISOString();
            }
          }
        );
        // Ordena las conversaciones de más reciente a menos reciente basándose en updatedAt
        state.filterConversations[teamId][conversationType][filterKey].conversations.sort(
          (a: { updatedAt: string | number | Date }, b: { updatedAt: string | number | Date }) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

      //actualizar el updatedAt en la conversacion dentro del estado de conversaciones
      state.conversations[conversationId].updatedAt = new Date().toISOString();
    });

    builder.addCase(orderConversationsByUpdatedAt, (state, action) => {
      // Sorting the conversations array by updatedAt in descending order (newest first)
      const { teamId, filter } = action.payload;
      if (!state.filterConversations[teamId]) return;
      const newstate = { ...state.filterConversations[teamId] };

      ['chat', 'clients'].forEach((sectionName) => {
        const section = newstate[sectionName];
        section &&
          Object.keys(section).forEach((filterName) => {
            if (filterName === filter) {
              const filter = section[filterName];
              filter.conversations.sort((a, b) => {
                return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
              });
            }
          });
      });
      state.filterConversations[teamId] = newstate;
    });

    builder.addCase(addConversationToFilter, (state, action) => {
      const teamId = action.payload.teamId;
      // Asegúrate de que el tipo y el filtro existan en el estado
      if (!state.filterConversations[action.payload.type]) {
        state.filterConversations[action.payload.type] = {};
      }
      if (!state.filterConversations[teamId]?.[action.payload.type]?.[action.payload.filter]) {
        state.filterConversations[teamId][action.payload.type][action.payload.filter] = {
          conversations: [],
          conversationCountWithOpenFragment: 0,
          countValidateUnread: false
        };
      }

      const filter =
        state.filterConversations[teamId]?.[action.payload.type]?.[action.payload.filter];
      const conversationExists = filter.conversations.some(
        (conver) => conver.id === action.payload.conversationId
      );

      if (!conversationExists) {
        filter.conversations.unshift({
          id: action.payload.conversationId,
          updatedAt: new Date().toISOString(),
          messageCount: 0,
          unreadMessageCount: 0
        });
        filter.conversationCountWithOpenFragment += 1;
      }
    });

    builder.addCase(outConversationToFilter, (state, action) => {
      const { filter: targetFilter, conversationId, teamId } = action.payload;

      for (const platform in state.filterConversations?.[teamId]) {
        for (const filter in state.filterConversations?.[teamId]?.[platform]) {
          // Si se proporciona un filtro y no coincide con el actual, continuamos con la siguiente iteración.
          if (targetFilter && targetFilter !== filter) {
            continue;
          }

          const conversationIndex = state.filterConversations?.[teamId]?.[platform]?.[
            filter
          ]?.conversations?.findIndex((conver) => conver?.id === conversationId);

          if (conversationIndex !== -1) {
            // Eliminar la conversación basándonos en su índice
            state.filterConversations?.[teamId]?.[platform]?.[filter]?.conversations?.splice(
              conversationIndex,
              1
            );

            // Eliminar el recuento de mensajes no leídos para la conversación eliminada
            const currentCount: number =
              state.filterConversations?.[teamId]?.[platform]?.[filter]
                ?.conversationCountWithOpenFragment;

            state.filterConversations[teamId][platform][filter] = {
              ...state.filterConversations?.[teamId]?.[platform]?.[filter],
              conversationCountWithOpenFragment: currentCount - 1
            };
            // eliminar la conversacion de la cola de salida
            delete state.outConversationToFilterQueue?.[conversationId];
          }
        }
      }
    });

    builder.addCase(setNewConversation, (state, action) => {
      // ver si la conversacion existe en el state.conversations
      const conversationExists = state.conversations[action.payload._id];

      if (!conversationExists) {
        state.conversations[action.payload._id] = action.payload;
      } else {
        state.conversations[action.payload._id].updatedAt = action.payload.updatedAt;
        state.conversations[action.payload._id].fragments = action.payload.fragments;
      }
    });
    builder.addCase(compareConversation, (state, action) => {
      state.conversations[action.payload._id] = action.payload;
    });

    builder.addCase(updateAnyPropsOfOneConversations, (state, action) => {
      const conversationId = action.payload.id;
      const keyToUpdate = action.payload.key;
      const value = action.payload.value;

      state.conversations[conversationId] = {
        ...state.conversations[conversationId],
        [keyToUpdate]: value
      };
    });

    builder.addCase(setNewGroup, (state, action) => {
      state.newGroup = action.payload;
    });

    builder.addCase(clearOutConversationToStateById, (state, action) => {
      delete state.conversations[action.payload];
    });
    builder.addCase(removeConversationFromFilter, (state, action) => {
      const teamId = action.payload.teamId;
      const converId = action.payload.conversationId;

      // Verificar que filterConversations y teamId existan
      if (state.filterConversations && state.filterConversations[teamId]) {
        Object.keys(state.filterConversations[teamId]).forEach((type) => {
          if (state.filterConversations[teamId][type]) {
            Object.keys(state.filterConversations[teamId][type]).forEach((filterName) => {
              const filter = state.filterConversations[teamId][type][filterName];

              if (filter && filter.conversations) {
                const index = filter.conversations.findIndex((conver) => conver.id === converId);

                if (index !== -1) {
                  // Eliminar la conversación del array
                  filter.conversations.splice(index, 1);
                }
              }
            });
          }
        });
      }
    });

    builder.addCase(cleanStateConversation, (state) => {
      state.conversations = {};
      state.error = {
        status: false,
        msg: ''
      };
      state.filterConversations = {};
      state.numberOfConversations = {};
      state.loading = false;
      state.loadingGetAllFilter = false;
      state.conversation = {} as Conversation;
      state.id_conversation = '';
      state.newGroup = false;
      state.pagesOfFilters = {};
      state.outConversationToFilterQueue = {};
      state.waitingForResponse = false;
    });
    builder.addCase(setPagesOfFilters, (state, action) => {
      state.pagesOfFilters = {
        ...state.pagesOfFilters,
        ...action.payload
      };
    });
    builder.addCase(setErrorToGetConversations, (state, action) => {
      state.error = {
        status: action.payload?.status,
        msg: action.payload?.message
      };
    });
  }
});
export const {
  setLoading,
  setLoadingGetAllFilter,
  cleanDataFilterConversations,
  setOutConversationToFilterQueue,
  setAddLabelsInConversation
} = conversationSlice.actions;

export const clickNotification =
  (id: string): Thunk =>
  async (dispatch) => {
    dispatch(setIdConversation(id));
  };
export const addConverAndFilterToState = (data: any) => async (dispatch, getState) => {
  const state = getState();
  const messagesState = state.messages.sections;
  const teamId = state.user.team?.id;
  const messagesNoReadInConver =
    messagesState[data?.conversation._id]?.filter((item) => {
      return (
        item?.type === 'message' &&
        item?.data?.status === 'delivered' &&
        (item?.data?.origin !== OriginOfMessage?.internal ||
          item?.data?.origin !== OriginOfMessage?.server)
      );
    }).length ?? 0;

  try {
    dispatch(setNewConversation(data.conversation));
    dispatch(
      setUnreadCountToConversation({
        conversationId: data.conversation._id,
        count: messagesNoReadInConver
      })
    );

    if (data.conversation.platform !== OriginOfMessage?.internal) {
      if (!data.filter) {
        dispatch(
          addConversationToFilter({
            filter: ALL,
            type: ETypesSections?.clients,
            conversationId: data.conversation._id,
            teamId
          })
        );
        dispatch(
          addConversationToFilter({
            filter: ABIERTOS,
            type: ETypesSections?.clients,
            conversationId: data.conversation._id,
            teamId
          })
        );
      } else {
        if (data?.filter === SIN_ASIGNAR) {
          //si el filtro es sin asignar se agrega en todos
          dispatch(
            addConversationToFilter({
              filter: data.filter,
              type: ETypesSections?.clients,
              conversationId: data.conversation._id,
              teamId
            })
          );
          dispatch(
            addConversationToFilter({
              filter: ALL,
              type: ETypesSections?.clients,
              conversationId: data.conversation._id,
              teamId
            })
          );
          dispatch(
            addConversationToFilter({
              filter: ABIERTOS,
              type: ETypesSections?.clients,
              conversationId: data.conversation._id,
              teamId
            })
          );
        } else {
          dispatch(
            addConversationToFilter({
              filter: data.filter,
              type: ETypesSections?.clients,
              conversationId: data.conversation._id,
              teamId
            })
          );
        }
      }

      dispatch(
        setUnreadCountToConversation({
          conversationId: data.conversation._id,
          count: messagesNoReadInConver
        })
      );
    } else if (!data.filter) {
      if (data.conversation.group.status === true) {
        dispatch(
          addConversationToFilter({
            filter: GRUPOS,
            type: ETypesSections?.chat,
            conversationId: data.conversation._id,
            teamId
          })
        );
      } else {
        dispatch(
          addConversationToFilter({
            filter: TODOS,
            type: ETypesSections?.chat,
            conversationId: data.conversation._id,
            teamId
          })
        );
      }
    }
  } catch (error) {
    console.log('ERROR ADDCONVERANDFILTERTOSTATE', error);
  }
};

export const createConversation =
  (currentFilter: string | any, conversationSend: PropsCreateConversation): Thunk =>
  async (dispatch): Promise<AxiosResponse<string> | AxiosError<string>> => {
    try {
      const data = await axiosInstance.post(`/chat/createConversation`, conversationSend);
      if (data.data?.status == '404' || data.data?.status == '400') {
        console.error(
          '%c [Create Conversation] Finish ERROR:',
          `color:#FFF; background-color: red;`,
          data.data
        );
        throw data?.data;
      }
      const { participants, section, fragment, conversation } = data.data.data;
      dispatch(setSections(section));
      dispatch(setFragments(fragment));
      dispatch(addConverAndFilterToState({ conversation, filter: currentFilter }));
      dispatch(setParticipants(participants));

      return conversation?._id as AxiosResponse<string>;
    } catch (err) {
      throw err;
    }
  };

export const createSectionsByConversationId =
  ({ fragments, messages }) =>
  async () => {
    const newConversations = {};

    if (fragments) {
      Object.keys(fragments)?.forEach((fragmentId) => {
        const messagesFragment = messages[fragmentId];
        const conversationId = messagesFragment[0]?.id_conversation;
        const mapedMessages = messagesFragment.map((message) => {
          return {
            type: 'message',
            id: message._id,
            data: message
          };
        });

        const section = [
          {
            type: 'footer',
            id: fragmentId,
            data: []
          },
          ...mapedMessages,
          {
            type: 'header',
            id: fragmentId,
            data: []
          }
        ];
        // Inicializa la conversación en el estado si aún no existe
        if (!newConversations[conversationId]) {
          newConversations[conversationId] = {};
        }
        // Añade el fragmento a la conversación
        newConversations[conversationId] = section;
      });
      return newConversations;
    }
  };

export const getAllConversationsByFilter =
  ({
    query,
    filter,
    type,
    teamId
  }: {
    query: IQuery;
    filter: string;
    type: ETypesSections;
    teamId: string;
  }): Thunk =>
  async (dispatch): Promise<AxiosResponse | AxiosError> => {
    if (!filter || !type || !teamId) return;

    try {
      const response = await axiosInstance.post(`/conversations/get-all-conversations-by-filter/`, {
        query
      });
      let unreadCount = {} as { [id_conversation: string]: number };
      let count: FilterTypeConversation = {
        [filter]: {
          conversations: [],
          //unread: {},
          conversationCountWithOpenFragment: 0,
          countValidateUnread: false
        }
      };
      // despues se setea los filtros y cantidad de mensajes no leidos indexados por id_conver
      if (response?.data?.count) {
        count = response?.data?.count;
        unreadCount = response?.data?.count?.[filter]?.unread;
      }

      dispatch(
        setConversations({
          conversations: response?.data?.conversations
        })
      );
      dispatch(
        setPagesOfFilters({
          [filter]: {
            page: response.data.page,
            nextPage: response.data.nextPages,
            totalPages: response.data.totalPage
          }
        })
      );
      dispatch(setUnreadCountState(unreadCount));
      if (query?.page === 1 || query?.page === 0) {
        // sobreescribe lo que esta en el filtro por lo nuevo
        dispatch(
          setFilterState({
            filter,
            type,
            count,
            teamId
          })
        );
      } else {
        // añade al final del filtro los datos nuevos
        dispatch(
          setFilterStateAndUnreadCount({
            filter,
            type,
            count,
            teamId
          })
        );
      }
      dispatch(setSections(response?.data?.section));
      // dispatch(orderConversationsByUpdatedAt(filter));
      // se guardan los particiapntes
      dispatch(setParticipants(response.data.participants));
      // se guradan los fragmentos
      dispatch(setFragments(response.data.fragments ?? {}));
      dispatch(setLoading(false));
      dispatch(setProgress(10));
      return response.data as AxiosResponse;
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(expiredToken(true));
      }

      error?.code !== 'ERR_CANCELED' && dispatch(setLoading(false));

      // dispatch(setErrorToGetConversations('Error al obtener las conversaciones'));
      throw error?.response?.data as AxiosError;
    }
  };

export const createGroupConversation =
  ({ id, participants, id_solicitante, name }: ProsCreateGroup): Thunk =>
  async () => {
    console.log(
      '%c [ADD PARTICIPANT] Init: waiting for response...',
      `color:#FFF; background-color: blue; padding:15px;`
    );

    try {
      const data = await axiosInstance.post(`/chat/updateConversation/${id}`, {
        name,
        participants,
        id_solicitante
      });

      if (data.data.status == '404') {
        throw new Error();
      }
      return data.data.data?._id as AxiosResponse<string>;
    } catch (error) {
      console.log(
        '%c [ADD PARTICIPANT] Finish Error Axios: ',
        `color:#FFF; background-color: red;`,
        error
      );
      return '404' as unknown as AxiosError<string>;
    }
  };

export const changeNameGroup =
  ({ id_conversation, name }: PropsChangeNameGroup): Thunk =>
  async () => {
    console.log(
      '%c [CHANGE NAME] Init: waiting for response...',
      `color:#FFF; background-color: blue; padding:15px;`
    );

    try {
      const data = await axiosInstance.post(
        `/chat/updateNameGrpoupByConversationId/${id_conversation}`,
        {
          name
        }
      );

      if (data.data.status == '404') {
        throw new Error(data.data);
      }
      // dispatch(compareConversation(data.data.data));
      return data.data.data?._id as AxiosResponse<string>;
    } catch (error) {
      console.log(
        '%c [ADD PARTICIPANT] Finish Error Axios: ',
        `color:#FFF; background-color: red;`,
        error
      );
      return '404' as unknown as AxiosError<string>;
    }
  };

export const updateConversationStatus =
  ({ id, status }: UpdateConverStatus): Thunk =>
  async (): Promise<AxiosResponse | AxiosError> => {
    try {
      const data = await axiosInstance.put(`/chat/updateConversationStatus/${id}`, {
        status
      });
      if (data.data?.status == '404') {
        // dispatch(setErrorToGetConversations('conversations.error.getConversations'));
      }
      if (data.data?.status == '200') {
        // dispatch(updateStatusToConversation(data.data.data));
      }

      return data.data as AxiosResponse;
    } catch (error) {
      return error as AxiosError;
    }
  };

export const addConverToState =
  (props: { conversation: Conversation; filter: string; fragments: any; teamId: string }) =>
  (dispatch) => {
    dispatch(addConverAndFilterToState({ conversation: props.conversation, filter: props.filter }));
    dispatch(setNewFragment(props.fragments));
    dispatch(orderConversationsByUpdatedAt({ filter: props.filter, teamId: props.teamId }));
  };

export const dispatchActions = (actions) => async (dispatch) => {
  return Promise.all(actions.map((action) => dispatch(action)));
};

export default conversationSlice.reducer;
