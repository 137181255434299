import { StyleSheet, useWindowDimensions } from 'react-native';
import React, { useEffect, useMemo } from 'react';
import translate from '@/helpers/traslations';
import { ScrollView } from 'react-native-gesture-handler';
import { Spinner, Text, View } from '@gluestack-ui/themed';
import InputComponent from '../conversation/footer/InputComponent';
import ResponseMessages from './ResponseMessages';
import FilterOmniboxComponent from './FilterOmniboxComponent';
import ResponseConversations from './ResponseConversations';

import { useAppDispatch, useAppSelector } from '@/redux/store';
import AcordionCustom from '../../atoms/AcordionCustom';
import MainCta from '../../atoms/MainCta';
import { outConversationToFilter } from '@/redux/actionTypes';
import { useOmnibox } from './OmniboxProvider';
import { FilterOmniBox } from './interfaces';
import TextInfo from '../../atoms/TextInfo';
import { IconArrowRight, Mangify } from '@/assets/icons/Icons';
import EmptySearchContact from '../emptystates/EmptySearchContact';
import { ThemedScrollView, TText, TView } from '../themed';
import { InputSlot } from '@gluestack-ui/themed';

function OmniboxComponent({ setModalVisible }) {
  const {
    onPressMessage,
    loadingSelected,
    loading,
    loadingMore,
    error,
    setSelectedFilters,
    setSearchQuery,
    data,
    searchQuery,
    loadMoreConversation,
    loadMoreMessages,
    selectedFilters,
    onPressConver,
    noResults
  } = useOmnibox();

  const outConversationToFilterQueue = useAppSelector(
    (state) => state.conversations.outConversationToFilterQueue
  );
  const dispatch = useAppDispatch();

  const filters: FilterOmniBox[] = [
    { key: 'conversations', label: 'Conversaciones', value: 'conversations' },
    // { key: 'tasks', label: 'Tarea', value: 'tasks' },
    // { key: 'contacts', label: 'Contacto', value: 'contacts' },
    { key: 'messages', label: 'Mensajes', value: 'messages' }
  ];
  const onChangeSearch = (query) => setSearchQuery(query);
  const { width } = useWindowDimensions();
  // saca las conversaciones de los filtros que estan en cola
  useEffect(() => {
    if (Object.keys(outConversationToFilterQueue).length > 0) {
      Object.keys(outConversationToFilterQueue).forEach((key) => {
        dispatch(outConversationToFilter(outConversationToFilterQueue[key]));
      });
    }
  }, []);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        element: {
          padding: 10,
          // borderBottomColor: colors.border,
          borderBottomWidth: 1
        },
        ctaBottom: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: 10
        }
      }),
    []
  );

  return (
    <>
      <TView style={{ marginVertical: 5, height: 40 }}>
        <InputComponent
          multiline={false}
          placeholder="Buscar..."
          value={searchQuery}
          autofocus
          onChange={onChangeSearch}
          disabled={false}
          leftComponent={
            <InputSlot flexDirection="row" gap="$1">
              <MainCta iconColor="$iconos" icon={Mangify} iconSize="md" variant="outline" />
            </InputSlot>
          }
        />
      </TView>
      <TView style={{ height: '100%', flex: 1 }}>
        <TView border="border" style={styles.element}>
          <TText size="sm">{translate('applyFilters')}</TText>
          <FilterOmniboxComponent
            filters={filters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </TView>

        {data && (
          <ThemedScrollView
            style={{
              flex: 1,
              height: '100%'
            }}
            showsVerticalScrollIndicator={false}
          >
            {(selectedFilters.includes('conversations') || selectedFilters?.length === 0) && (
              <AcordionCustom key="conversations" idString="conversations" defaultOpen>
                {loading.conversations ? (
                  <Spinner color="$pxsol" />
                ) : (
                  <>
                    {error?.conversations && (
                      <TText
                        size="sm"
                        style={{ color: '#555', fontStyle: 'italic', textAlign: 'center' }}
                      >
                        {translate('messageError.errorConversations')}
                      </TText>
                    )}
                    {data?.listConversations?.listConversations?.length > 0 ? (
                      <ResponseConversations
                        loading={loadingSelected}
                        conversations={data?.listConversations?.listConversations}
                        onSelectConversation={onPressConver}
                      />
                    ) : noResults?.conversations ? (
                      <EmptySearchContact />
                    ) : (
                      <TText
                        size="sm"
                        style={{ color: '#555', fontStyle: 'italic', textAlign: 'center' }}
                      >
                        {translate('noConversations')}
                      </TText>
                    )}
                    {data?.listConversations?.nextPage > 0 && (
                      <TView bgColor="primary" style={styles.ctaBottom}>
                        <MainCta
                          labelId="loadMore"
                          iconPosition="right"
                          icon={IconArrowRight}
                          variant="solid"
                          action="secondary"
                          loading={loadingMore.conversations}
                          disabled={loadingMore.conversations}
                          onPress={() => loadMoreConversation(data?.listConversations?.nextPage)}
                        />
                      </TView>
                    )}
                  </>
                )}
              </AcordionCustom>
            )}
            {(selectedFilters.includes('messages') || selectedFilters?.length === 0) && (
              <AcordionCustom key="message" idString="message" defaultOpen>
                {loading.messages ? (
                  <Spinner color="$pxsol" />
                ) : (
                  <>
                    {error?.messages ? (
                      <TText
                        size="sm"
                        style={{ color: '#555', fontStyle: 'italic', textAlign: 'center' }}
                      >
                        {translate('messageError.errorMessages')}
                      </TText>
                    ) : data?.listMessage?.listMessage?.length > 0 ? (
                      <ResponseMessages
                        loading={loadingSelected}
                        onPressMessage={onPressMessage}
                        messages={data?.listMessage?.listMessage}
                      />
                    ) : noResults?.messages ? (
                      <TextInfo text={translate('noMessages')} />
                    ) : (
                      <TText
                        size="sm"
                        style={{ color: '#555', fontStyle: 'italic', textAlign: 'center' }}
                      >
                        {translate('noMessages')}
                      </TText>
                    )}

                    {data?.listMessage?.nextPage && (
                      <TView style={styles.ctaBottom}>
                        <TText size="sm" style={{ color: '#555', fontStyle: 'italic' }}>
                          {data?.listMessage?.currentPage}
                        </TText>
                        <MainCta
                          labelId="loadMore"
                          iconPosition="right"
                          icon={IconArrowRight}
                          variant="outline"
                          action="secondary"
                          loading={loadingMore.messages}
                          disabled={loadingMore.messages}
                          onPress={() => loadMoreMessages(data?.listMessage?.nextPage)}
                        />
                      </TView>
                    )}
                  </>
                )}
              </AcordionCustom>
            )}
          </ThemedScrollView>
        )}
      </TView>
    </>
  );
}

export default React.memo(OmniboxComponent);
