import React, { useMemo } from 'react';

import { Badge, BadgeText, TooltipContent, Tooltip } from '@gluestack-ui/themed';
import MemoizedAvatarCard from '@/atoms/AvatarCard';
import translate from '@/helpers/traslations';
import { FragmentAssignee } from './interfaces';
import { THView, TText, TVView } from '@/components/themed';
import { StyleSheet } from 'react-native';
import { getActionBadgeStatus } from '@/utils/functions';
import { Status } from '@/types/index';

function ChipAssignee({ assignees, status }: { assignees: FragmentAssignee[]; status: Status }) {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        title: {
          lineHeight: 4
        },
        containerAsignee: {
          paddingVertical: 4,
          borderTopWidth: 1,
          gap: 8
        },
        itemAssignee: {
          gap: 4
        }
      }),
    []
  );

  return (
    <Tooltip
      placement="top"
      trigger={(triggerProps) => {
        return (
          <Badge
            {...triggerProps}
            maxWidth={120}
            size="sm"
            variant="solid"
            action={getActionBadgeStatus(status)}
            paddingHorizontal={'$1'}
          >
            <BadgeText bold numberOfLines={1} textTransform="capitalize">
              {`${assignees[0]?.nombre} ${assignees[0]?.apellido}`}
              {assignees?.length > 1 ? ` +${assignees?.length - 1}` : ''}
            </BadgeText>
          </Badge>
        );
      }}
    >
      <TooltipContent p="$2" maxWidth="$72" bg="$background">
        <TVView
          bgColor="background"
          style={{
            gap: 8
          }}
        >
          <TText size="sm" style={styles.title}>
            {assignees?.length > 1 ? translate('assignees') : translate('assignee')}
          </TText>
          <TVView style={styles.containerAsignee} border="border">
            {assignees?.map((user) => {
              return (
                <THView key={user?.IDUsuarios} style={styles.itemAssignee}>
                  <MemoizedAvatarCard
                    width="$5"
                    sizeAvatar="xs"
                    picture={user?.foto}
                    name={user?.nombre}
                  />
                  <TText size="sm" style={styles.title}>
                    {`${user?.nombre} ${user?.apellido}`}
                  </TText>
                </THView>
              );
            })}
          </TVView>
        </TVView>
      </TooltipContent>
    </Tooltip>
  );
}

export default React.memo(ChipAssignee, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.assignees) === JSON.stringify(nextProps.assignees);
});
