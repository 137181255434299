import { View, Text } from 'react-native';
import React, { memo, useEffect, useMemo, useState } from 'react';
import MainCta from '@/atoms/MainCta';
import { Star, StarOutline } from '@/assets/icons/Icons';
import translate from '@/helpers/traslations';
import { updateLabelsInConversation } from '@/redux/slices/labels/requests';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { useLinkss } from '@/providers/LinkProvider';
import { IMPORTANTS } from '@/utils/filters';
import { Label } from '@/redux/slices/labels/interfaces';
import { outConversationToFilter } from '@/redux/actionTypes';
import { setAddLabelsInConversation } from '@/redux/slices/conversation';
import { isArray } from '@/utils/functions';

const ItemImportant = ({
  platform,
  conversationId
}: {
  platform: string;
  conversationId: string;
}) => {
  const dispatch = useAppDispatch();
  const conversations = useAppSelector((state: RootState) => state.conversations.conversations);
  const labels = useAppSelector((state: RootState) => state.labels.labels);
  const teamId = useAppSelector((state: RootState) => state.user?.team?.id);

  const { showToast, handleCopyCode } = useLinkss();

  const labelsConversation = useMemo(() => {
    return conversations[conversationId]?.tags;
  }, [conversations[conversationId], conversationId]);

  const selectedLabels = useMemo(() => {
    return labels.filter((label: Label) => labelsConversation?.includes(label?.id?.toString()));
  }, [labelsConversation, labels]);

  const isImportant = useMemo(() => {
    return selectedLabels?.find((item: Label) => item?.id?.toString() === IMPORTANTS);
  }, [selectedLabels]);

  const handleImportant = (e: { stopPropagation: () => void; preventDefault: () => void }) => {
    e.stopPropagation();
    e.preventDefault();
    // setIsImportant(!isImportant);
    dispatch(
      updateLabelsInConversation({
        conversationsId: [conversationId],
        action: isImportant ? 'remove' : 'set',
        labels: [IMPORTANTS]
      })
    )
      .then((res) => {
        dispatch(setAddLabelsInConversation({ conversationId, label: IMPORTANTS }));
        showToast({
          title: translate('success'),
          subtitle: translate(
            !isImportant ? 'messages.markedAsImportant' : 'messages.markedAsNotImportant'
          ),
          type: 'success',
          variant: 'accent',
          duration: 2000
        });
        isImportant &&
          dispatch(
            outConversationToFilter({
              conversationId,
              filter: '136',
              teamId: teamId
            })
          );
      })
      .catch((err) => {
        console.log(err);
        if (isArray(err?.message)) {
          err?.message.forEach((error: string) => {
            showToast({
              type: 'error',
              title: `ERROR: ${err?.transactionId}`,
              subtitle: error,
              cta: (
                <MainCta
                  labelId={'actions.copy'}
                  uppercase
                  variant="outline"
                  onPress={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handleCopyCode(err?.transactionId);
                  }}
                />
              )
            });
          });
          return;
        }
        showToast({
          type: 'error',
          title: 'Error',
          subtitle: err.message
        });
      });
  };

  return (
    platform !== 'internal' && (
      <MainCta
        icon={isImportant ? Star : StarOutline}
        iconColor={isImportant ? 'yellow' : '$disabled'}
        sx={{
          width: 30,
          height: 30,
          padding: 0
        }}
        variant="outline"
        onPress={(e: { stopPropagation: () => void; preventDefault: () => void }) =>
          handleImportant(e)
        }
      />
    )
  );
};

export default memo(ItemImportant);
