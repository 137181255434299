// Import module
import React, { createContext, memo, useContext, useEffect, useState } from 'react';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import Constants from 'expo-constants';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { RegisterToken } from '../redux/slices/auth';
import { playAudio } from '@/utils/web/functions';

const NotificationContext = createContext<{
  beamsClient: PusherPushNotifications.Client | null;
  toggleNotifications: () => void;
  notificationsEnabled: boolean;
  dennied: boolean;
}>({
  beamsClient: null,
  toggleNotifications: () => {},
  notificationsEnabled: false,
  dennied: false
});

export const useNotifications = () => {
  return useContext(NotificationContext);
};

function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [value, setValue] = useState<PusherPushNotifications.Client | null>(null);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [dennied, setDennied] = useState(false);
  const dispatch = useAppDispatch();
  const idUser = useAppSelector((state: RootState) => state.user.user?.id);
  const auth0Token = useAppSelector((state: RootState) => state.user.auth0Token);
  const soundNotification = useAppSelector(
    (state: RootState) => state.user.user?.notifications_config?.sound_notification
  );
  const statusNotification = useAppSelector(
    (state: RootState) => state.user.user?.notifications_config?.status_notification
  );
  const { beamsInstance, backendUrl } = Constants.expoConfig?.extra || {};
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    if (!statusNotification) return;
    // check if browser is safari or not
    if (!idUser || !beamsInstance || !backendUrl) {
      return;
    }

    if (!isSafari && 'serviceWorker' in navigator) {
      const beamsClient = new PusherPushNotifications.Client({
        instanceId: beamsInstance
      });

      let beamsTokenProvider: PusherPushNotifications.TokenProvider =
        new PusherPushNotifications.TokenProvider({
          url: `${backendUrl}/chat/pusher/beams/auth/${idUser}`,
          headers: {
            Authorization: `Bearer ${auth0Token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        });

      if (beamsClient) {
        setValue(beamsClient);
        startBeams(beamsClient, beamsTokenProvider);
      }
    }
  }, [idUser, statusNotification]);

  async function startBeams(
    beamsClient: PusherPushNotifications.Client,
    beamsTokenProvider: PusherPushNotifications.ITokenProvider
  ) {
    beamsClient
      .start()
      .then((beamsClient: any) => beamsClient?.getDeviceId())
      .then((deviceId) => {
        console.log('Successfully registered with Beams. Device ID:');
        if (!deviceId || !idUser) return;
        dispatch(RegisterToken({ id: idUser, token: deviceId, device: 'web' }))
          .then((res) => {
            console.log('beams Successfully registered token with backend', res);
          })
          .catch((err) => {
            console.error('beams Error registering token with backend', err);
          });
        setNotificationsEnabled(true);
        beamsClient
          .setUserId(idUser.toString(), beamsTokenProvider)
          .then((res) => {
            console.log('beams Successfully subscribed to beams with user ID:', idUser.toString(), {
              res
            });

            document.addEventListener('visibilitychange', function () {
              // Check if the document has become visible
              if (document.visibilityState === 'visible') {
                // Make sure the service worker is ready
                if ('serviceWorker' in navigator) {
                  navigator.serviceWorker.ready.then(function (registration) {
                    // Get all the currently displayed notifications
                    registration.getNotifications().then(function (notifications) {
                      for (let i = 0; i < notifications.length; i++) {
                        notifications[i].close();
                      }
                    });
                  });
                }
              }
            });
          })
          .catch((err) => {
            console.error('beams Error subscribing to beams with user ID:', idUser.toString(), {
              err
            });
          });
      })
      .catch((err) => {
        setNotificationsEnabled(false);
        console.info('beams PUSH NOTIFICATION ERROR => Error registering with Beams', err);
      });
  }

  function toggleNotifications() {
    if (notificationsEnabled && value) {
      value
        .stop()
        .then((res) => {
          console.log('beams Successfully stopped notifications', res);
          setNotificationsEnabled(false);
        })
        .catch((err) => {
          console.log('beams Error stopping notifications', err);
        });
      // canlcelar suscripción a notificaciones del navegador
    } else {
      // Solicitar permiso para notificaciones
      if (!('Notification' in window)) {
        console.log('This browser does not support system notifications.');
        return;
      }
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Permission granted');
          setDennied(false);
          console.log('beams Starting notifications', value);
          const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
            url: `${backendUrl}/chat/pusher/beams/auth/${idUser}`,
            headers: {
              Authorization: `Bearer ${auth0Token}`,
              'ngrok-skip-browser-warning': 'true'
            }
          });
          if (value) {
            startBeams(value, beamsTokenProvider);
          }
        } else if (permission === 'denied') {
          setDennied(true);
          console.log('beams Permission denied');
        } else {
          // El usuario cerró el cuadro de diálogo sin tomar una decisión
          // "default" es el valor que toma
          setDennied(true);
          console.log('Permission not given by user');
        }
      });
    }
  }

  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.action === 'NAVIGATE_TO_URL') {
      } else if (event.data && event.data.action === 'FILTER_CONVERSATION_BY_ID') {
      } else if (event.data && event.data.action === 'PLAY_SOUND') {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              soundNotification && playAudio();
            } else {
              console.log('Notification permission denied');
            }
          })
          .catch((error) => {
            console.error('Error requesting notification permission:', error);
          });
      }
    });
  }, []);

  return (
    <NotificationContext.Provider
      value={{ beamsClient: value, toggleNotifications, notificationsEnabled, dennied }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export default memo(NotificationProvider);
