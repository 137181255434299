import { BadgeIcon, Badge, BadgeText } from '@gluestack-ui/themed';

import React from 'react';
import { FilterOmniBox, FilterOmniBoxProps } from './interfaces';
import { Check } from '@/assets/icons/Icons';
import { THView, TPressable, TText } from '../themed';

function FilterOmniboxComponent({
  selectedFilters,
  setSelectedFilters,
  filters,
  unique = false,
  type = 'filtros'
}: FilterOmniBoxProps) {
  const isSelected = (value) => selectedFilters?.includes(value);

  const toggleSelect = (value) => {
    if (unique) {
      setSelectedFilters([value]);
      return;
    }
    if (isSelected(value)) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
    } else {
      setSelectedFilters([...selectedFilters, value]);
    }
  };
  // this a test comment
  return (
    <THView
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 8,
        gap: 8
      }}
    >
      {filters?.length > 0 ? (
        filters?.map((filter: FilterOmniBox) => (
          <TPressable
            style={{ borderRadius: 8 }}
            key={filter.value}
            onPress={() => toggleSelect(filter.value)}
          >
            <Badge
              key={filter.key}
              variant="outline"
              borderRadius="$sm"
              action="muted"
              bg={isSelected(filter.value) ? '$backgroundSecondary' : '$background'}
              style={{
                gap: 5
              }}
            >
              {isSelected(filter.value) && <BadgeIcon as={Check} size="xs" mr="$1" />}
              <BadgeText size="2xs">{filter.label}</BadgeText>
              {filter?.name && <BadgeText size="2xs">{filter?.name}</BadgeText>}
            </Badge>
          </TPressable>
        ))
      ) : (
        <TText>{`No hay ${type}`}</TText>
      )}
    </THView>
  );
}

export default React.memo(FilterOmniboxComponent);
