import React, { lazy, memo, useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { filtersBase } from '@/utils/filters';
import { Filters } from '@/redux/slices/filters/interfaces';
import { TView } from '@/components/themed';

import { RootState, useAppSelector } from '@/redux/store';
import { generateUUID } from '@/utils/functions';

const AcordionCustom = lazy(() => import('@/atoms/AcordionCustom'));
const ContainerItemDrawer = lazy(
  () => import('@/components/sidebar/sidebarFilters/ContainerItemDrawer')
);
const CustomSkeleton = lazy(() => import('../../CustomSckeleton'));

const ContainerFilters = ({
  collapsed,
  routeName,
  activeRoute,
  handlePressedFilter
}: {
  collapsed: boolean;
  routeName: string;
  activeRoute: string;
  handlePressedFilter: (filter: Filters) => void;
}) => {
  const filters = useAppSelector((state: RootState) => state.filters.filters);
  const [filtrosAsignacion, setFiltrosAsignacion] = useState<Filters[]>([]);

  const skeleton = 5;

  const styles = useMemo(
    () =>
      StyleSheet.create({
        childrenAcordion: {
          minWidth: 150,
          flexGrow: 1,
          width: '100%'
        }
      }),
    []
  );

  useEffect(() => {
    const filtrosAsig = filters?.assignee?.filter((filter) => filter.types.includes(routeName));
    setFiltrosAsignacion(filtrosAsig);
  }, [filters, routeName]);

  return (
    <AcordionCustom collapsed={collapsed} idString="conversations" defaultOpen>
      <TView bgColor="transparent" style={styles.childrenAcordion}>
        {filtrosAsignacion?.length > 0
          ? filtrosAsignacion.map(
              (item: Filters) =>
                item?.idString !== 'searchs' && (
                  <ContainerItemDrawer
                    key={item?.id}
                    collapsed={collapsed}
                    item={item}
                    handlePressedFilter={handlePressedFilter}
                    routeName={routeName}
                    activeRoute={activeRoute}
                  />
                )
            )
          : Array(skeleton)
              .fill(0)
              .map((item, index) => (
                <CustomSkeleton
                  key={item + generateUUID()}
                  type="rectangle"
                  height={25}
                  width={170}
                  background="$secondary"
                />
              ))}

        {activeRoute === 'searchs' && (
          <ContainerItemDrawer
            collapsed={collapsed}
            key={generateUUID()}
            item={filtersBase.find((item) => item.idString === 'searchs')}
            handlePressedFilter={handlePressedFilter}
            routeName={routeName}
            activeRoute={activeRoute}
          />
        )}
      </TView>
    </AcordionCustom>
  );
};

export default memo(ContainerFilters, (prevProps, nextProps) => {
  return (
    prevProps.collapsed === nextProps.collapsed &&
    prevProps.routeName === nextProps.routeName &&
    prevProps.activeRoute === nextProps.activeRoute &&
    prevProps.handlePressedFilter === nextProps.handlePressedFilter
  );
});
