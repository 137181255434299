import React, { memo, useState } from 'react';
import AcordionCustom from '@/atoms/AcordionCustom';

import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { FilterIcon, InfoOutline, VolumeMedium, VolumeOff } from '@/assets/icons/Icons';
import translate from '@/helpers/traslations';

import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';

import { updateNotification } from '@/redux/slices/auth/requests';
import { IUpdateNotification } from '@/redux/slices/auth/interfaces.d';
import { useLinkss } from '@/providers/LinkProvider';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import CardCustom from '@/atoms/CardCustom';
import { playAudio } from '@/utils/web/functions';
import { ASIGNADOS_A_MI, SIN_ASIGNAR } from '@/utils/filters';
import getIconsToFilters from '@/assets/icons';

const AssignedNotification = () => {
  const dispatch = useAppDispatch();
  const notificationsAssigned = useAppSelector(
    (state: RootState) => state.user.user?.notifications_config?.assigned_to_me
  );
  const [updatingNotification, setUpdatingNotification] = useState<boolean>(false);
  const [value, setValue] = useState<boolean>(notificationsAssigned);
  const { showToast } = useLinkss();

  const disableAssignedToMe = () => {
    setUpdatingNotification(true);
    setValue(!notificationsAssigned);
    dispatch(
      updateNotification({
        propertyNotification: IUpdateNotification.assignedToMe,
        data: !notificationsAssigned
      })
    )
      .then((response) => {
        showToast({
          type: 'success',
          title: translate('success'),
          subtitle: translate('messages.successToUpdateStatus'),
          variant: 'accent'
        });
      })
      .catch((error) => {
        setValue(notificationsAssigned);
        showToast({
          type: 'error',
          title: translate('error'),
          subtitle: translate('messageError.errorToUpdateStatus'),
          variant: 'accent'
        });
      })
      .finally(() => {
        setUpdatingNotification(false);
      });
  };

  return (
    <AcordionCustom
      iconLeft={FilterIcon}
      idString="notification.assigned"
      titleSize="lg"
      bgColor="$background2"
      mbItem="$0"
      defaultOpen={false}
      sx={{
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginBottom: 20,
        borderRadius: 8
      }}
      middleElement={
        <CustomTooltip title={translate('notification.assignedTooltip')}>
          <MaterialCommunity icon={InfoOutline} size="md" color="$info" />
        </CustomTooltip>
      }
    >
      <CardCustom
        key={ASIGNADOS_A_MI}
        idString={`filtros.${ASIGNADOS_A_MI}`}
        idStringSubtitle="notification.assignedToMe"
        leftElement={
          <MaterialCommunity
            icon={getIconsToFilters({ idString: 'contacts' })}
            color="$text"
            size="xl"
          />
        }
        switchProp={{
          value: value,
          onChange: () => disableAssignedToMe(),
          disabled: updatingNotification
        }}
      />
    </AcordionCustom>
  );
};

export default memo(AssignedNotification);
