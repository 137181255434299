import { createAction } from '@reduxjs/toolkit';
import { Conversation } from '../../types/conversation';
import { Message } from '../../types/message';
import { FilterTypeConversation, PagesOfFilters } from '../slices/conversation/interfaces';
import {
  SetNewParticipant,
  ParticipantsConverastionsIndexed
} from '../slices/participants/interfaces';
import { MoreMessages, SetImagesStateInterface } from '../slices/messages/interfaces';
import {
  Channel,
  Company,
  Contacts,
  IUserNotifications,
  ListCurrency,
  ListLanguage,
  Product,
  SalesPoint,
  Team,
  Teams,
  User
} from '@/types/typesRedux/interfaces';
import { Filters, OrderOptions, contracts } from '../slices/filters/interfaces';
import { Label } from '../slices/labels/interfaces';
import { Workflow } from '@/components/automatizations/interface.d';
import { TypeSections } from '@/types/enums';
import { ETypesSections } from '@/routers/MainRouter';

interface SaveTokenAuth0Payload {
  idToken: string;
}
interface Login {
  user: User;
  company: Company;
  team: Team;
  product: Product;
  salesPoint: SalesPoint;
  teams: Teams[];
  contracts: contracts[];
  links: any[];
  currenlyTeams: Teams[];
}
export const setUserNotificationConfig = createAction<IUserNotifications>(
  'SETUSERNOTIFICATIONCONFIG'
);
export const setUserlaoding = createAction<boolean>('SETUSERLOADING');
export const login = createAction<Login>('LOGIN');
export const saveTokenAuth0 = createAction<SaveTokenAuth0Payload>('SAVETOKENAUTH0');
export const setSocket = createAction<boolean>('SETSOCKET');
export const cleanStateUser = createAction('CLEANSTATEUSER');
export const LoginError = createAction<string>('LOGINERROR');
export const expiredToken = createAction<boolean>('EXPIREDTOKEN');
export const setTeamUser = createAction<Team>('SETTEAMUSER');
export const setChannels = createAction<Channel[]>('SETCHANNELS');
export const setLanguage = createAction<ListLanguage[]>('SETLANGUAGE');
export const setCurrency = createAction<ListCurrency[]>('SETCURRENCY');
export const setVersion = createAction<boolean>('SETVERSION');
export const setProgress = createAction<number>('SETPROGRESS');
export const setViewSliderVersion = createAction<boolean>('SETVIEWSLIDERVERSION');
export const setIntroShown = createAction<boolean>('SETINTROSHOWN');
// Actions Of Conversations

interface MessageAndNoty {
  msg: Message[];
  id: string | undefined;
}

interface Incremetn {
  conversationId: string;
  cant: number;
}

interface ISetUnreadCount {
  conversationId: string;
  count: number;
}
interface IUpdateUpdateAtConversation {
  type: ETypesSections;
  conversationId: string;
  teamId: string;
}

interface IAddConversationId {
  filter: string;
  type: ETypesSections;
  conversationId: string;
  teamId: string;
}
export const setConversations = createAction<any>('SETCONVERSATIONS');
export const clearOutConversationToStateById = createAction<string>(
  'CLEAROUTCONVERSATIONTOSTATEBYID'
);
export const setPagesOfFilters = createAction<PagesOfFilters>('SETPAGESOFFILTERS');
export const removeConversationFromFilter = createAction<{
  conversationId: string;
  teamId: string;
}>('REMOVECONVERSATIONFROMFILTER');

export const setErrorToGetConversations = createAction<{ message: string; status: boolean }>(
  'SETERRORTOGETCONVERSATIONS'
);
export const filterConversationById = createAction<string>('FILTERCONVERSATIONBYID');
export const compareConversation = createAction<Conversation>('COMPARECONVERSATION');
export const setNewConversation = createAction<any>('SETNEWCONVERSATION');
export const setMessageToConversation = createAction<MessageAndNoty>('SETMESSAGETOCONVERSATION');
export const outConversationToFilter = createAction<{
  conversationId: string;
  filter: string;
  teamId: string;
}>('OUTCONVERSATIONTOFILTER');
export const setWaitingForResponse = createAction<boolean>('SETWAITINGFORRESPONSE');
export const updateAnyPropsOfOneConversations = createAction<any>(
  'UPDATEANYPROPSOFONECONVERSATIONS'
);
export const setFilterStateAndUnreadCount = createAction<any>('SETFILTERSTATEANDUNREADCOUNT');
export const setUnreadCountToConversation = createAction<{
  conversationId: string;
  count: number;
}>('SETUNREADCOUNTTOCONVERSATION');

export const setUnreadCountState = createAction<{
  [id_conversation: string]: number;
}>('SETUNREADCOUNTSTATE');

export const setFilterState = createAction<{
  type: ETypesSections;
  filter: string;
  count: FilterTypeConversation;
  teamId: string;
}>('SETFILTERSTATE');
export const addConversationToFilter = createAction<IAddConversationId>('ADDCONVERSATIONTOFILTER');
export const verifyConverInState = createAction<string>('VERIFYCONVERINSTATE');
export const orderConversationsByUpdatedAt = createAction<any>('ORDERCONVERSATIONSBYUPDATEDAT');
export const updateUpdateAtConversation = createAction<IUpdateUpdateAtConversation>(
  'UPDATEUPDATEATCONVERSATION'
);
export const setParticipants = createAction<ParticipantsConverastionsIndexed>('SETPARTICIPANTS');
export const setIdConversation = createAction<string>('SETIDCONVERSATION');
export const cleanStateConversation = createAction('CLEANSTATECONVERSATION');
export const setNewGroup = createAction<boolean>('NEWGROUP');
export const clearConversation = createAction('CLEARCONVERSATION');
export const setNewsParticipants = createAction<SetNewParticipant>('SETNEWSPARTICIPANTS');
export const updateParticipant = createAction<any>('UPDATEPARTICIPANT');
export const clearParticipantsByConversationId = createAction<string>(
  'CLEARPARTICIPANTSBYCONVERSATIONID'
);
export const setNewConversationToState = createAction<{ [index: string]: Conversation }>(
  'SETNEWCONVERSATIONTOSTATE'
);
// Theme Actions
export const setThemeMode = createAction<string>('SETTHEMEMODE');

// Contacts Actions
export const setIdContacto = createAction<string>('SETIDCONTACTS');
export const setContacts = createAction<Contacts[]>('SETCONTACTS');
export const setLoadingContactos = createAction<boolean>('SETLOADINGCONTACTOS');
export const clearAllInfoContactos = createAction('CLEARALLINFOCONTACTOS');
export const cleanContacts = createAction('CLEANCONTACTS');
// Messages Actions
export const setLoadingMessage = createAction<boolean>('SETLOADINGMESSAGE');
export const setFlagMessage = createAction<boolean>('SETFLAGMESSAGE');
export const moreMessages = createAction<MoreMessages>('MOREMESSAGES');
export const setImagesToState = createAction<SetImagesStateInterface>('SETIMAGESTOSTATE');
export const clearMessagesByConverationId = createAction<string>('CLEARMESSAGESBYCONVERATIONID');
export const setUploadFile = createAction<boolean>('SETUPLOADFILE');
export const setZeroUnreadCount = createAction<any>('SETZEROUNREADCOUNT');

export const setSections = createAction<any>('SETSECTIONS');
export const setSectionsByConverId = createAction<any>('SETSECTIONSBYFRAGMENTID');

export const addOrUpdateMessageInFragment = createAction<any>('ADDORUPDATEMESSAGEINFRAGMENT');
export const addNewFragmentoToSection = createAction<any>('ADDNEWFRAGMENTOTOSTATE');
export const setFilters = createAction<{
  assignee: Filters[];
  agents: Filters[];
  labels?: Filters[];
}>('SETFILTERS');
export const setNewFilterToKey = createAction<{ key: string; value: any }>('SETNEWFILTERTOKEY');
export const setOrderToFilters = createAction<OrderOptions>('SETORDERTOFILTERS');
export const clearSections = createAction('CLEARSECTIONS');

export const setFragments = createAction<any>('SETFRAGMENTS');
export const setSectionBefore = createAction<any>('SETSECTIONBEFORE');

export const setLabels = createAction<Label[]>('SETLABELS');
export const setNewLabel = createAction<Label>('SETLABEL');
export const deleteLabel = createAction<{ id: number }>('DELETELABEL');
export const updateTagNameLabelById = createAction<{ id: number; tag_name: string }>(
  'UPDATETAGNAMELABELBYID'
);
export const clearLabels = createAction('CLEARLABELS');

// Automatization Actions
export const setNewWorkflow = createAction<{ idString: string; workflow: Workflow }>(
  'SETNEWWORKFLOW'
);
export const setWorkflows = createAction<{ idString: string; workflows: Workflow[] }>(
  'SETWORKFLOWS'
);
export const setCreatingWorkflow = createAction<Workflow>('SETCREATINGWORKFLOW');
export const setCustomPropietyOfWorkflow = createAction<{
  idString: string;
  value: any;
  propiety: string;
}>('SETCUSTOMPROPIETYOFWORKFLOW');

export const setWorflowTemplates = createAction<any>('SETWORFLOWTEMPLATES');
export const deleteWorkflowOfState = createAction<{ id: string; idString: string }>(
  'DELETEWORKFLOWOFSTATE'
);

export const setNoTemplates = createAction<{ idString: string; status: boolean }>('SETNOTEMPLATES');
