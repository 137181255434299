import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  MenuItem,
  MenuItemLabel,
  Pressable,
  Menu,
  Icon,
  HStack,
  ModalContent,
  Modal,
  ModalBackdrop,
  ModalBody
} from '@gluestack-ui/themed';

import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParams } from '@/routers/interfaces';
import translate from '@/helpers/traslations';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import MaterialCommunity from '../../Icons/MaterialCommunity';
import {
  DotsVertical,
  EyeOff,
  SwapHorizontal,
  VolumeMedium,
  VolumeOff
} from '@/assets/icons/Icons';
import {
  moveConversationOfTeam,
  setConversationWhoNotRead
} from '@/redux/slices/conversation/requests';
import {
  clearOutConversationToStateById,
  outConversationToFilter,
  setUnreadCountToConversation
} from '@/redux/actionTypes';
import { useLinkss } from '@/providers/LinkProvider';
import { Teams } from '@/types/typesRedux/interfaces';
import MainCta from '@/atoms/MainCta';
import { copyToClipboard, isArray } from '@/utils/functions';
import { PropsMoreOptions } from './interfaces';
import RenderCompanys from '../renderTeam/RenderCompanys';
import { updateNotification } from '@/redux/slices/auth/requests';
import { IUpdateNotification } from '@/redux/slices/auth/interfaces.d';

function MoreOptions({ conversationId }: PropsMoreOptions) {
  const dispatch = useAppDispatch();
  const route = useRoute<RouteProp<RootStackParams>>();
  const teams: Teams[] = useAppSelector((state: RootState) => state.user.teams);
  const type = useAppSelector((state: RootState) => state.filters.type);

  const conversationNotification = useAppSelector(
    (state: RootState) => state.user.user?.notifications_config?.conversation_notification
  );

  const [visible, setVisible] = useState(false);
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [info, setInfo] = useState(false);
  const [updatingNotification, setUpdatingNotification] = useState<boolean>(false);

  const { showToast } = useLinkss();

  const isMuted = useMemo(() => {
    return conversationNotification?.includes(conversationId);
  }, [conversationNotification, conversationId]);

  const closeSubMenu = () => setSubmenuVisible(false);

  const handCopyCode = useCallback(async (text) => {
    if (text) {
      const copied = await copyToClipboard(text);
      if (copied) {
        showToast({
          subtitle: `${translate('copied')} ${text}`,
          type: 'info'
        });
      } else {
        console.log('Error al copiar');
      }
    }
  }, []);

  const handleSelectedFilter = (item) => {
    if (!info) {
      showToast({
        type: 'info',
        title: 'Info',
        variant: 'accent',
        subtitle: translate('messages.waitMovingConversation')
      });
      setInfo(true);
    }

    dispatch(
      moveConversationOfTeam({
        team_id: item.id,
        conversation_id: conversationId
      })
    )
      .then((res) => {
        showToast({
          type: 'success',
          title: translate('success'),
          variant: 'accent',
          subtitle: translate('messages.successToMoveCoversationOfTeam'),
          cta: (
            <MainCta
              labelId={'actions.copy'}
              uppercase
              variant="outline"
              onPress={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                handCopyCode(res?.transactionId);
              }}
            />
          )
        });
        dispatch(
          outConversationToFilter({
            conversationId: conversationId,
            filter: '',
            teamId: route?.params?.team
          })
        );
        dispatch(clearOutConversationToStateById(conversationId));
      })
      .catch((err) => {
        if (isArray(err?.message)) {
          err?.message?.forEach((error: string) => {
            showToast({
              type: 'error',
              title: `ERROR: ${err?.transactionId}`,
              subtitle: error,
              cta: (
                <MainCta
                  labelId={'actions.copy'}
                  uppercase
                  variant="outline"
                  onPress={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handCopyCode(err?.transactionId);
                  }}
                />
              )
            });
          });
          return;
        }

        showToast({
          type: 'error',
          title: 'Error',
          variant: 'accent',
          subtitle: translate('messageError.errorToMoveConversationOfTeam'),
          cta: err?.transactionId && (
            <MainCta
              labelId={'actions.copy'}
              uppercase
              variant="outline"
              onPress={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                handCopyCode(err?.transactionId);
              }}
            />
          )
        });
      })
      .finally(() => {
        closeSubMenu();
      });
  };

  const handlePressNotRead = () => {
    dispatch(setConversationWhoNotRead({ conversationId }))
      .then((res) => {
        console.log('res', res);
        showToast({
          type: 'success',
          title: translate('success'),
          variant: 'accent',
          subtitle: translate('messages.successToMarkConversationAsNotRead')
        });
        dispatch(
          setUnreadCountToConversation({
            conversationId: conversationId,
            count: 1
          })
        );
      })
      .catch((err) => {
        console.log('err handlePressNotRead', err);

        showToast({
          type: 'warning',
          title: translate('warning'),
          variant: 'accent',
          subtitle: err?.message ?? translate('messageError.errorToMarkConversationAsNotRead')
        });
      });
    setVisible(true);
  };

  const hadleMuteConversation = () => {
    setUpdatingNotification(true);
    dispatch(
      updateNotification({
        propertyNotification: IUpdateNotification.converstionNotification,
        data: [conversationId]
      })
    )
      .then((response) => {
        showToast({
          type: 'success',
          title: translate('success'),
          subtitle: translate('messages.successToUpdateStatus'),
          variant: 'accent'
        });
      })
      .catch((error) => {
        showToast({
          type: 'error',
          title: translate('error'),
          subtitle: translate('messageError.errorToUpdateStatus'),
          variant: 'accent'
        });
      })
      .finally(() => {
        setUpdatingNotification(false);
      });
  };
  return (
    <HStack>
      <Menu
        key={conversationId}
        isOpen={visible}
        trigger={({ ...triggerProps }) => {
          return (
            <Pressable
              style={{
                width: 30,
                height: 30,
                borderRadius: 100,
                justifyContent: 'center',
                alignItems: 'center'
              }}
              sx={{
                ':hover': {
                  backgroundColor: '$backgroundSecondary'
                }
              }}
              {...triggerProps}
              onPress={() => setVisible(true)}
            >
              <Icon as={DotsVertical} size="sm" />
            </Pressable>
          );
        }}
        onClose={() => setVisible(false)}
        selectionMode="single"
      >
        <MenuItem
          key="move-to-menu"
          textValue="move-to-menu"
          onPress={() => {
            setSubmenuVisible(true);
            setVisible(false);
          }}
        >
          <MaterialCommunity icon={SwapHorizontal} size="sm" />
          <MenuItemLabel ml="$2" size="sm">
            {translate('moveTo')}
          </MenuItemLabel>
        </MenuItem>
        <MenuItem
          key={'not-read'}
          textValue="not-read"
          onPress={() => {
            handlePressNotRead();
            setVisible(false);
          }}
        >
          <MaterialCommunity icon={EyeOff} size="sm" />
          <MenuItemLabel ml="$2" size="sm">
            {translate('markNotRead')}
          </MenuItemLabel>
        </MenuItem>
        <MenuItem
          key={'notification-conversation'}
          textValue="notification-conversation"
          onPress={() => {
            hadleMuteConversation();
            setVisible(false);
          }}
          disabled={updatingNotification}
        >
          <MaterialCommunity icon={isMuted ? VolumeOff : VolumeMedium} size="sm" />
          <MenuItemLabel ml="$2" size="sm">
            {translate(
              isMuted ? 'notification.unmuteConversation' : 'notification.muteConversation'
            )}
          </MenuItemLabel>
        </MenuItem>
      </Menu>
      <Modal isOpen={submenuVisible} onClose={() => setSubmenuVisible(false)} size="sm">
        <ModalBackdrop />
        <ModalContent
          alignSelf="center"
          maxHeight="60%"
          height="auto"
          paddingHorizontal="$0"
          paddingVertical="$4"
          backgroundColor="$backgroundNavigationPxsol"
        >
          <ModalBody>
            {teams?.length > 0 &&
              teams?.map((company: Teams) => (
                <RenderCompanys
                  key={company?.id_company}
                  item={company}
                  route={route}
                  onPressItem={handleSelectedFilter}
                />
              ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
}

export default React.memo(MoreOptions);
