import React from 'react';
import translate from '@/helpers/traslations';
import FilterOmniboxComponent from '@/components/omnibox/FilterOmniboxComponent';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { setSelectedChannelToInitConversation } from '@/redux/slices/theme';
import MaterialCommunity from '@/components/Icons/MaterialCommunity';
import { InfoOutline } from '@/assets/icons/Icons';
import { THView, TText, TVView } from '@/components/themed';

function PopoverChannels({ channelWhatsapp }) {
  const dispatch = useAppDispatch();
  const selectedChannel = useAppSelector(
    (state: RootState) => state.settings.selectedChannelToInitConversation
  );
  const popoverChannel = useAppSelector((state: RootState) => state.settings.popoverChannel);

  const setSelectedChannel = (channel) => {
    dispatch(setSelectedChannelToInitConversation(channel));
  };

  console.log('channelWhatsapp', channelWhatsapp);

  return (
    <TVView
      border="border"
      style={{
        gap: 5
      }}
    >
      <TText>{translate('selectChannelToInitConversaton')}</TText>
      <THView
        border={popoverChannel ? 'pxsol' : 'transparent'}
        style={{
          borderWidth: popoverChannel ? 1 : 0,
          borderRadius: 8,
          padding: 4
        }}
      >
        <FilterOmniboxComponent
          filters={channelWhatsapp}
          selectedFilters={selectedChannel}
          setSelectedFilters={setSelectedChannel}
          unique
          type={'canales activos'}
        />
        {popoverChannel && <MaterialCommunity icon={InfoOutline} size="sm" color="$pxsol" />}
      </THView>
    </TVView>
  );
}

export default React.memo(PopoverChannels);
