import { Linking } from 'react-native';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Routes } from '../routers/routes';

import {
  cleanPhoneNumber,
  copyToClipboard,
  findConversationFilter,
  IS_WEB_PC
} from '@/utils/functions';

import translate from '../helpers/traslations';
import { ALL } from '@/utils/filters';
import { createConversation } from '../redux/slices/conversation';
import { Spinner, Toast, useToast, ToastDescription, ToastTitle } from '@gluestack-ui/themed';
import { getConversAndMessages } from '@/redux/slices/conversation/requests';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';
import { ToastProps } from '../types';
import { HandleCopyCodeProps } from './interfaces';
import { CommonActions, NavigationContainerRefWithCurrent } from '@react-navigation/native';
import { navigationRef } from '@/routers/MainRouter';
import ModalInitConver from '@/components/partials/linksProvider/ModalInitConver';
import { PlatformsEnum } from '@/types/enums/conversation';
import { setSelectedChannelToInitConversation } from '@/redux/slices/theme';
import {
  setIdConversation,
  setProgress,
  setUserlaoding,
  setWaitingForResponse
} from '@/redux/actionTypes';
import { firstCharge } from '@/redux/slices/auth';
import { Channel } from '@/screens/interfaces';
import { TView, TVView } from '@/components/themed';
import { RootStackParams } from '@/routers/interfaces';
import { FilterOmniBox } from '@/components/omnibox/interfaces';

const LinksContext = createContext<{
  handleDeepLink: ({ url }: { url: string }) => void;
  loadingURL: boolean;
  id_message: string;
  showToast: (props: ToastProps) => void;
  handleCopyCode: ({ text }: HandleCopyCodeProps) => void;
  isReady: boolean;
  setIsReady: (value: boolean) => void;
}>(null);

export const navigateToConversation = ({
  idConver,
  idMessage,
  platform,
  filter
}: {
  idConver: string;
  idMessage?: string;
  platform: string;
  filter: string;
}) => {
  IS_WEB_PC
    ? navigationRef.current?.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: platform === 'chat' ? Routes.Chat : Routes.clientes,
              state: {
                index: 0,
                routes: [
                  {
                    name: filter,
                    params: { filter, id: idConver },
                    state: {
                      index: 0,
                      routes: [
                        {
                          name: Routes.Conversation,
                          params: { id: idConver, index: idMessage }
                        }
                      ]
                    }
                  }
                ]
              }
            }
          ]
        })
      )
    : navigationRef?.current?.navigate(Routes.ConversationStack, {
        id: idConver,
        filter,
        index: idMessage
      });
};

export const useLinkss = () => {
  return useContext(LinksContext);
};

function LinkProvider(LinkNavigation: {
  navigationReady: any;
  navigationRef: NavigationContainerRefWithCurrent<RootStackParams>;
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal;
}) {
  const dispatch = useAppDispatch();
  const navigationReady = LinkNavigation.navigationReady;
  const team = useAppSelector((state: RootState) => state.user?.team?.id);
  const filterConversations = useAppSelector(
    (state: RootState) => state.conversations.filterConversations?.[team]
  );
  const id_empresa = useAppSelector((state: RootState) => state.user.company.IDEmpresas);
  const selectedChannel = useAppSelector(
    (state: RootState) => state.settings.selectedChannelToInitConversation
  );
  const activeChannels = useAppSelector(
    (state: RootState) => state.user.activeChannels?.[id_empresa]
  );

  const [loadingURL, setLoadingURL] = useState<boolean>(false);
  const [id_message, setId_message] = useState<string>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [participant, setParticipant] = useState<{ numberPhone: string; idContactPms: string }>(
    null
  );
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [channelWhatsapp, setChannelWhatsapp] = useState<any>([]);
  const toast = useToast();
  let conversationIdUrl: string = '';

  const activeChannelsTeam: Channel[] = useMemo(() => {
    if (!team || !activeChannels) return [];
    return activeChannels?.filter((channel: Channel) => channel?.conection_status === 'enable');
  }, [team, activeChannels]);

  const setWhatsappChannel = () => {
    let channelWhatsapp: FilterOmniBox[] = [];

    activeChannelsTeam?.forEach((channel: Channel) => {
      if (channel?.channel_type === PlatformsEnum?.whatsapp) {
        channelWhatsapp.push({
          key: channel?.id?.toString(),
          label: channel?.channel_data,
          value: channel?.channel_id,
          icon: channel.channel_type,
          name: channel?.channel_label
        });
      }
    });
    return channelWhatsapp;
  };

  React.useEffect(() => {
    dispatch(setProgress(0));
    dispatch(setWaitingForResponse(true));
    dispatch(firstCharge())
      .then(() => {
        console.log('getUser --LinkProvider---');
      })
      .catch((error) => {
        console.error('getUser --LinkProvider---', error);
      })
      .finally(() => {
        dispatch(setUserlaoding(false));
        dispatch(setWaitingForResponse(false));
      });
  }, []);

  useEffect(() => {
    const bootstrapAsync = async () => {
      // Verifica si hay un deep link cuando se monta el componente
      const initialUrl = await Linking.getInitialURL();
      if (initialUrl) {
        setLoadingURL(true);
        handleDeepLink({ url: initialUrl });
        setLoadingURL(false);
      }
    };

    isReady && bootstrapAsync();
  }, [isReady]);

  // if (!isReady) return null;k
  const handleDeepLink = useCallback(({ url }: { url: string }) => {
    const urlObj = new URL(url);
    const segments = urlObj.pathname.split('/');
    console.log('handleDeepLink obj', urlObj);

    // const filterUrl = urlObj.searchParams.get('filter');
    const index = urlObj.searchParams.get('index');
    const phone = urlObj.searchParams.get('phone');
    const contactId = urlObj.searchParams.get('contactId');
    if (segments[4] === 'newconversationexternal' && phone && contactId) {
      createConversationWithDeepLink(phone, contactId);
      return;
    }
    conversationIdUrl = segments[4];

    if (conversationIdUrl) {
      if (conversationIdUrl?.length !== 24) return false;
      // se guarda el id de la url para tenerlo disponible en el children del provider.
      dispatch(setIdConversation(conversationIdUrl));
      const findInState = findConversationFilter(conversationIdUrl, filterConversations);
      if (index) {
        setId_message(index);
        console.info('buscando conver y mensaje');
        dispatch(
          getConversAndMessages({
            id_message: index,
            id: conversationIdUrl,
            filter: findInState?.filterKey ?? 'searchs',
            navigate: true,
            teamId: team
          })
        ).catch((err) => {
          console.log('err getConversAndMessages', err);
          setLoadingURL(false);
        });
        setLoadingURL(false);
      } else {
        console.info('esta la conver');
        dispatch(
          getConversAndMessages({
            id: conversationIdUrl,
            filter: findInState?.filterKey ?? 'searchs',
            navigate: true,
            teamId: team
          })
        ).catch((err) => {
          console.log('err getConversAndMessages', err);
          setLoadingURL(false);
        });
        setLoadingURL(false);
      }
    } else {
      dispatch(setIdConversation(''));
      setLoadingURL(false);
    }
  }, []);

  const createConversationWithDeepLink = (phone: string, contactId: string) => {
    //limpiar el contactId dejar solo numeros por si viene con otra cosa al final.
    const cleanContactId = contactId?.replace(/[^0-9]/g, '');
    const numberPhone = cleanPhoneNumber(phone);
    const part = [{ numberPhone, idContactPms: cleanContactId }];
    setParticipant({ numberPhone, idContactPms: contactId });
    const channelWhatsapp = setWhatsappChannel();
    dispatch(setSelectedChannelToInitConversation([channelWhatsapp[0]?.value]));
    setChannelWhatsapp(channelWhatsapp);
    if (channelWhatsapp?.length === 1) {
      handleAcceptConversation({ part, channel: channelWhatsapp[0]?.value });
    }
    setShowModal(true);
  };

  const handleAcceptConversation = ({ part = null, channel = null }) => {
    setLoadingCreate(true);
    dispatch(
      createConversation(ALL, {
        status: 'new',
        participants: part ?? [participant],
        company_id: `${id_empresa}`,
        team_id: team,
        platform: PlatformsEnum?.whatsapp,
        getMessages: false,
        group: {
          status: false,
          name: '',
          avatar: ''
        },
        id_channel: channel ?? selectedChannel[0]
      })
    )
      .then((res: unknown) => {
        console.log('createConversation', res);
        dispatch(setSelectedChannelToInitConversation([]));
        setLoadingCreate(false);
        dispatch(setIdConversation(res as string));
        setShowModal(false);
        navigateToConversation({
          idConver: res as string,
          platform: PlatformsEnum?.whatsapp,
          filter: ALL
        });
      })
      .catch((err) => {
        console.log('err createConversation', err);
        setLoadingCreate(false);
        showToast({
          subtitle: err?.msg ?? translate('messageError.noCreateConversation'),
          type: 'error',
          variant: 'accent',
          duration: 5000
        });
      });
  };

  const showToast = ({
    type,
    title,
    subtitle,
    variant = 'solid',
    cta = null,
    duration = 5000
  }: ToastProps) => {
    toast.show({
      placement: IS_WEB_PC ? 'bottom left' : 'top',
      duration: duration,
      render: ({ id }) => {
        const toastId = `toast-${id}`;
        return (
          <Toast
            alignItems="center"
            zIndex={999999999}
            id={toastId}
            action={type}
            variant={variant}
          >
            <TVView
              style={{
                gap: 8
              }}
            >
              <ToastTitle>{title}</ToastTitle>
              <ToastDescription>{subtitle}</ToastDescription>
            </TVView>
            {cta}
          </Toast>
        );
      }
    });
  };

  const handleCopyCode = useCallback(async ({ text }: HandleCopyCodeProps) => {
    if (text) {
      const copied = await copyToClipboard(text);
      if (copied) {
        showToast({
          subtitle: `¡${translate('copied')}!`,
          type: 'success',
          variant: 'accent'
        });
        return true;
      } else {
        console.log('Error al copiar');
        return false;
      }
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      handleDeepLink,
      loadingURL,
      id_message,
      showToast,
      handleCopyCode,
      isReady,
      setIsReady
    }),
    [handleDeepLink, loadingURL, id_message, showToast, handleCopyCode, isReady, setIsReady]
  );

  return (
    navigationReady && (
      <LinksContext.Provider value={contextValue}>
        {loadingURL ? (
          <TView
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Spinner size="small" color="$pxsol" />
          </TView>
        ) : (
          LinkNavigation?.children
        )}
        <ModalInitConver
          showModal={showModal}
          setShowModal={setShowModal}
          handleAcceptConversation={handleAcceptConversation}
          teamId={team}
          loading={loadingCreate}
          channelWhatsapp={channelWhatsapp}
        />
      </LinksContext.Provider>
    )
  );
}

export default React.memo(LinkProvider);
