const resource = {
  Audio: 'Audio',
  additionalComment: 'Comentario adicional',
  accept: 'Aceptar',
  answer: 'Respuesta',
  agents: 'Agentes',
  assignees: 'Responsables',
  activated: 'Activado',
  applyFilters: 'Aplicar filtros',
  associations: 'Asociaciones',
  attach: 'Adjuntar',
  unactivated: 'Desactivado',
  File: 'Archivo',
  Gif: 'Gif',
  Image: 'Imagen',
  Paste: 'Pegar',
  Sticker: 'Sticker',
  aFile: 'un archivo',
  aSticker: 'un sticker',
  abrir: 'Abrir',
  ajustsOfHotel: 'Ajustes del hotel',
  ajustOfApp: 'Ajustes de la aplicación',
  bot: 'Bot',
  centerOfHelp: 'Centro de ayuda',
  changingTeam: 'Cambiando de equipo...',
  chargingData: 'Cargando datos...',
  channels: 'Canales',
  currency: 'Moneda',
  instructions: 'Instrucciones',
  colorAplication: 'Tema de la aplicación',
  copied: 'Copiado',
  copyToClipboard: 'Copiar al portapapeles',
  copyTheError: 'Copia el error',
  closeFragment: 'Cerrar fragmento',
  chatbot: 'Chatbot',
  actions: {
    actions: 'Acciones',
    activate: 'Activar',
    addRangeDayAndTime: 'Agregar rango de día y hora',
    AssociationBoooking: 'Asociar reserva',
    associateContact: 'Asociar contacto',
    associate: 'Asociar',
    associateRoom: 'Asociar habitación',
    apply: 'Aplicar',
    cancel: 'Cancelar',
    close: 'Cerrar',
    confirm: 'Confirmar',
    copy: 'Copiar',
    continue: 'Continuar',
    createQuery: 'Crear consulta',
    connectFree: 'Conectar gratis',
    connect: 'Conectar',
    configure: 'Configurar',
    delete: 'Eliminar',
    create: 'Crear',
    deleteMessage: 'Eliminar mensaje',
    edit: 'Editar',
    new: 'Nuevo',
    newLabel: 'Nueva etiqueta',
    add: 'Agregar',
    forward: 'Reenviar',
    generateResponse: 'Generar respuesta',
    login: 'Iniciar sesión',
    link: 'Vincular',
    next: 'Siguiente',
    previous: 'Anterior',
    reload: 'Recargar',
    reply: 'Responder',
    rewrite: 'Reescribir',
    changeContext: 'Cambiar contexto',
    changePrompt: 'Cambiar instrucción',
    talkToSales: 'Hablar con ventas',
    save: 'Guardar',
    select: 'Seleccionar',
    selectBot: 'Seleccionar bot',
    update: 'Actualizar',
    unassign: 'Revocar asignación',
    view: 'Ver',
    transcribe: 'Transcribir Audio',
    transcribing: 'Transcribiendo...',
    typeAName: 'Escribe un nombre',
    newAutomatization: 'Nueva automatización',
    sendOfTemplates: 'Envío de plantillas',
    automaticResponse: 'Respuesta automática',
    upgradePlan: 'Actualizar plan'
  },
  automatizations: {
    automatizationCreated: 'Automatización creada con éxito',
    automatizationUpdated: 'Automatización actualizada con éxito',
    dragAndDropTitle:
      'Importante! El orden de las automatizaciones aquí determina la secuencia de ejecución.',
    dragAndDrop: 'Arrastra y coloca las tarjetas en el orden deseado para definir la prioridad.',
    selectTheTriggerOfAutomation: 'Selecciona el desencadenador de la automatización',
    selectTheChannel: 'Selecciona el canal',
    youSureCancelEdit: '¿Estás seguro de cancelar la edición?',
    channelTooltip: 'Selecciona el canal donde se va a ejecutar la automatización',
    inconmingMessage: 'Mensaje entrante',
    inconmingMessageTooltip: 'Se ejecuta cuando se recibe un mensaje',
    typeConditions: 'Deben cumplirse:',
    typeConditionsTooltip:
      'Se refiere a las condiciones de fecha / día / hora / primer mensaje que deben cumplirse para que se ejecute la automatización',
    init: 'Inicia',
    initTooltip: 'Fecha y hora de inicio de la automatización',
    end: 'Finaliza',
    noEnd: 'Sin finalización',
    endTooltip: 'Fecha y hora de finalización de la automatización',
    initTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    triggerTooltip: 'Selecciona el desencadenador de la automatización',
    initTimeTooltip: 'Hora de inicio de la automatización',
    endTimeTooltip: 'Hora de finalización de la automatización',
    priorityChanged: 'Prioridad cambiada con éxito',
    errorToChangePriority: 'Error al cambiar la prioridad',
    templatesTooltip:
      'Selecciona la plantilla que se enviará al ejecutarse la automatización, las plantillas tiene que estar activas para poder utilizarse',
    botTooltip: 'Selecciona el bot que responderá al ejecutarse la automatización',
    dayOfWeek: 'Días de la semana',
    dayOfWeekTooltip: 'Selecciona los días de la semana en los que se ejecutará la automatización',
    errorToDeleteAutomatization: 'Error al eliminar la automatización',
    deleteAutomatization: '¿Estás seguro de eliminar la automatización?',
    deleteAutomatizationDescription: 'Esta acción no se puede deshacer',
    deletedAutomatization: 'Automatización eliminada'
  },
  axiosError: {
    fileSizeExceededLimitForThisTypeOfFile:
      'El tamaño del archivo excede el límite permitido para este tipo de archivo',
    notFileFound: 'Archivo no encontrado',
    conversationIdNotFound: 'No se encontró el id de la conversación',
    typeFileNotFound: 'Tipo de archivo no soportado en este canal',
    errorDistributingFile: 'Error al distribuir el archivo'
  },
  templates: {
    title: 'Plantillas',
    subtitle: 'Crea plantillas para enviar mensajes de forma rápida y sencilla.',
    createTemplateOfFirstContact:
      'Por políticas de Meta, no es posible enviar mensajes que no estén aprobados. Crea plantillas para que sean aprobadas por Meta y úsalas para abrir conversaciones.',
    newTemplate: 'Nueva plantilla',
    empty: 'Ups! Para iniciar este chat necesitas una plantilla preaprobada.',
    create: 'Crear plantilla',
    cancel: 'Ahora no',
    save: 'Guardar',
    send: 'Enviar',
    share: 'Compartir',
    talkToSales: 'Agendar Demo y Conexión',
    selectTemplate: 'Seleccionar plantilla',
    sendTemplate: 'Enviar plantilla'
  },
  addMembers: 'Agregar miembros',
  ageOfMinor: 'Edad del menor',
  minorAge: 'Edad del menor',
  addParticipants: 'Agregar Participantes',
  anAudio: 'un audio',
  anImage: 'una imagen',
  assignResponsible: 'Asignar responsable',
  assignee: 'Responsable',
  assigneeError: 'Error al asignar responsable',
  assigneeSuccess: 'Responsable asignado con éxito',
  avaliableChannels: 'Canales disponibles',
  alert: 'Alerta',
  availableTemplatesForFirstContact: 'Plantillas disponibles para el primer contacto',
  back: 'Volver',
  birthdate: 'Fecha de nacimiento',
  brand: 'Pxsol Conversaciones',
  booking: 'Reserva',
  bookings: 'Reservas',
  bots: 'Bots',
  businessname: 'Razón social',
  closed: 'Cerrado',
  checkIn: 'Check-in',
  checkOut: 'Check-out',
  config: 'Ajustes',
  contact: 'Contacto',
  contactos: 'Contactos',
  conversation: 'Conversación',
  conversations: 'Conversaciones',
  connect: 'Conectar',
  continue: 'Continuar',
  connectAnAssistantToYourTeam:
    'Para generar respuestas automaticas con inteligencia artificial conecta un asistente a tu equipo',
  yourTeamNotHaveAnAssistant: 'Tu equipo no tiene un asistente',
  company: 'Empresa',
  channel: 'Canal',
  commingSon: 'Próximamente',
  createContact: 'Crear contacto',
  createBooking: 'Crear reserva',
  cuit: 'CUIT',
  current: 'Actual',
  currencyChanged: 'Moneda cambiada con éxito',
  darkTheme: 'Tema oscuro',
  Dayisnotallowed: 'El día no está permitido',
  dataOfContact: 'Datos del contacto',
  description: 'Descripción',
  download: 'Descargar',
  dinamicContent: {
    dinamicContent: 'Contenido dinámico',
    template: 'Plantillas',
    actions: 'Acciones'
  },
  downloadoConversations: 'Descargando las conversaciones',
  email: 'Correo electrónico',
  empresa: 'Empresa',
  embedding: 'Base de conocimientos',
  error: 'Error',
  editContact: 'Editar contacto',
  establecimiento: 'Establecimiento',
  establishment: 'Establecimiento',
  exit: 'Salir',
  exitGroup: 'Salir del grupo',
  expired: 'Expirado',
  filters: 'Filtros',
  frecuentContacts: 'Contactos frecuentes',
  filtros: {
    asignados_a_mi: 'Asignado a mi',
    cerrados: 'Cerrados',
    expired: 'Vencidos',
    grupos: 'Grupos',
    sin_asignar: 'Sin asignar',
    todos: 'Todos',
    abiertos: 'Recibidos'
  },
  finish: 'Finalizar',
  failed: 'Fallido',
  conversationPending:
    'Conversación iniciada; esperando respuesta del cliente. Para cerrar la conversación y enviar un nuevo mensaje, deben transcurrir 24 horas',
  generalActivityIndicatorText: 'Cargando...',
  generalErrorText: 'Ocurrió un error, inténtalo de nuevo más tarde.',
  guests: 'Húespedes',
  gotToPms: 'Volver al PMS',
  getMore: 'Cargar más',
  greaters: 'Mayores',
  goToFirstMessage: 'Ir al primer mensaje',
  goToLastMessage: 'Ir al último mensaje',
  reponseGenerateOfIA: 'Respuestas automáticas generadas por la IA',
  hi: 'Hola',
  here: 'aquí',
  responseAutomatic: 'Respuesta automática',
  infoContact: 'Información de contacto',
  info: 'Información',
  infoConver: 'Información de la conversación',
  internal: 'Interno',
  important: 'Importante',
  lastname: 'Apellido',
  labels: 'Etiquetas',
  logout: 'Cerrar sesión',
  location: 'Ubicación',
  loadingConversations: 'Cargando las conversaciones',
  language: 'Idioma',
  languageChanged: 'Idioma cambiado con éxito',
  loadingData: 'Cargando datos...',
  loadMore: 'Cargar más',
  loadingTemplates: 'Cargando plantillas',
  members: 'Miembros',
  message: 'Mensajes',
  messageDeleted: 'Mensaje eliminado',
  messageUnsuported: 'Mensaje no soportado',
  messageError: {
    alreadyExistAssociationBooking: 'La reserva ya está asociada a la conversación',
    alreadyExistAssociationContact: 'El contacto ya está asociado a la conversación',
    noBookingAssociated: 'No hay reserva asociada',
    moreOneBookingAssociated: 'Más de una reserva asociada',
    checkOut: 'La fecha de Check-Out no puede ser anterior a la fecha de Check-In',
    checkIn: 'La fecha de Check-In no puede ser anterior a la fecha actual',
    checkOutRequired: 'La fecha de Check-Out es requerida',
    checkInRequired: 'La fecha de Check-In es requerida',
    errorMessages: 'Error al obtener los mensajes',
    converNotExist: 'La conversación no existe',
    defineAgeOfMinor: 'Por favor, define las edades de los menores',
    errorGetFirstData: 'Error al obtener los primeros datos',
    errorCiut: 'El número de documento debe tener 11 dígitos y solo números',
    errorNameIsNotEmpty: 'El nombre no puede estar vacío',
    errorNotClosedFragment: 'Error, para cerrar el fragmento primero debe asignarse',
    errorShareFile: 'Error al compartir el archivo',
    errorToChangeNameOfGroup: 'Error al cambiar el nombre del grupo',
    errorToClosedFragment: 'No se pudo cerrar el fragmento',
    errorToClosedFragmentIfUserIsNotAssignee:
      'No se puede cerrar el fragmento si el usuario no es el responsable',
    errorToUpdateStatus: 'Error al actualizar el estado',
    errorToCreateConversation: 'Error al crear la conversación',
    errorToGetConversation: 'Error al obtener la conversación',
    errorToDeleteParticipant: 'Error al eliminar el participante',
    errorToCreateGroup: 'Error al crear el grupo',
    errorToOutGroup: 'Error al salir de la grupo',
    errorToUpdateNotification: 'Error al actualizar la notificación',
    errorToAssociateBooking: 'Error al asociar la reserva',
    errorToAsscociateContact: 'Error al asociar el contacto',
    errorToCreateContact: 'Error al crear el contacto',
    errorInServerRetryLater: 'Error en el servidor, inténtalo de nuevo más tarde',
    errorContactAlreadyExist: 'El contacto ya existe',
    errorChangingLanguage: 'Error al cambiar el idioma',
    errorChangingCurrency: 'Error al cambiar la moneda',
    errorToMarkConversationAsNotRead: 'Error al marcar la conversación como no leída',
    errorConversations: 'Error al obtener las conversaciones',
    errorToGetValueOfLink: 'Error al obtener el valor de la variable',
    fileNotFound: 'Archivo no encontrado',
    filterInvalid: 'Filtro inválido, por favor seleccione uno filtro válido en el menú lateral',
    noContatcs: 'No hay contactos',
    noConvers: 'No hay conversaciones',
    noConversations: 'No hay conversaciones',
    noConnectSocket: 'No se pudo conectar al servidor',
    noCreateConversation: 'No se pudo crear la conversación',
    noCreateGroup: 'No se pudo crear el grupo',
    noAddParticipant: 'No se pudo agregar el participante',
    noDeleteParticipant: 'No se pudo eliminar el participante',
    noStickerFound: 'No se encontró el sticker',
    noPermissionNotification:
      'No tienes hanilitadas las notificaciones, habilita las notificaciones para recibir alertas de nuevos mensajes',
    fieldRequiered: 'Campo requerido',
    fileSizeExceeded: 'El tamaño del archivo excede el límite permitido 25MB',
    numberPhoneInvalid: 'Número de teléfono inválido',
    invalidEmail: 'Email inválido',
    imageSizeExceeded: 'El tamaño de la imagen excede el límite permitido 5MB',
    dateHaveToBeLessThanToday: 'La fecha tiene que ser menor a la fecha actual',
    roomsGreaterThan1: 'El número de habitaciones debe ser mayor a 1',
    ageLeast1: 'La edad de los menores debe ser mayor a',
    ageLessThan12: 'La edad de los menores debe ser menor a',
    selectAnEmbedding: 'Selecciona una base de conocimientos',
    errorToMoveConversationOfTeam: 'Ocurrió un error al mover la conversación de equipo',
    noBots: 'No  hay Bots',
    noContacts: 'No hay contactos',
    videoCouldNotBeLoaded: 'Error al cargar el video',
    noConfigOfChannel: 'No hay configuración de adjuntos para el canal',
    noAutomatizations: '¡Ups! ahún no has creado automatizaciones',
    0: 'Error al enviar el mensaje. CODIGO: 0',
    130472:
      'WhatsApp no podrá entregar este mensaje, si intenta reenviar el mensaje, se producirá el mismo error. Si debe entregar el mensaje de la plantilla de marketing al cliente, le recomendamos que se comunique con el cliente por algún otro medio que no sea WhatsApp y le solicite que le envíe un mensaje para que pueda reenviar el mensaje dentro de la ventana de servicio al cliente. CODIGO: 130472',
    131026:
      'El mensaje no se pudo enviar porque el cliente no tiene WhatsApp activo. Revisa el número de teléfono. CODIGO: 131026',
    131047:
      'El mensaje no se pudo enviar porque han pasado más de 24 horas desde la última vez que el cliente respondió a este número, intenta enviar una plantilla de primer contacto para reabrir la conversación. CODIGO: 131047',
    ERR_BAD_REQUEST: 'Error en la solicitud',
    2534080: 'El formato de archivo no es compatible. CODIGO: 2534080'
  },
  messages: {
    connectWhatsapp: '¿Aún no vinculaste tu cuenta de WhatsApp?',
    connectWhatsappButton: 'Conéctate ahora',
    connectInstagram: '¿Aún no vinculaste tu cuenta de Instagram?',
    connectInstagramButton: 'Conéctate ahora',
    connectFacebook: '¿Aún no vinculaste tu cuenta de Facebook?',
    connectFacebookButton: 'Conéctate ahora',
    connectWidget: '¿Aún no activaste tu widget?',
    connectWidgetButton: 'Conéctate ahora GRATIS',
    contactsRanked: 'Contactos frecuentes',
    useAsPrompt: 'Usar como prompt',
    loadingDefaultValue: 'Cargando valor por defecto...',
    channelDisabled: 'Canal deshabilitado',
    channelDisabledDescription:
      'El canal seleccionado está deshabilitado, vuelve a iniciar la conversación con el canal activo actual.',
    closeFragmentSubtitle:
      '¡Atención! Estás a punto de cerrar este fragmento de la conversación. Si lo haces, para continuar dialogando deberás iniciar un nuevo fragmento mediante una plantilla de primer contacto o esperar a que el cliente responda. ¿Deseas proceder con el cierre de la conversación actual?',
    closeWidget: 'Conversación finalizada',
    closeWidgetSubtitle:
      'No se pueden reabrir las conversaciones que vienen de un widget web porque no se puede garantizar que el usuario siga en línea. Si el usuario cierra la página web o no envía mensajes por 15 minutos, la conversación expira.',
    dateOfInit: 'Fecha de inicio',
    fileAlreadyExists: 'El archivo ya existe',
    infoSelectParticipant: 'Elimina el participante desde la pestaña miembros',
    fragmentOpen: 'Fragmento abierto',
    generateSmartResponse: 'Generar respuesta inteligente',
    initConversation: 'Iniciar conversación',
    instructionForAgentResponse:
      'Ingresa la instrucción de cómo quieres que el agente redacte la respuesta. Ej: Responde al usuario de manera amable que no tenemos disponibilidad, o responde al usuario de manera formal pasándole la disponibilidad para la fecha de su consulta.',
    instructionForAgentRewrite:
      'Dile al agente cómo reescribir el texto. Ej: traducir al inglés, redactar como email, etc.',
    extendAnswer: 'Expandir mensaje',
    newContact: 'Nuevo contacto',
    noLabels: 'No hay etiquetas',
    labelName: 'Nombre de la etiqueta',
    labelDescription: 'Escribe una descripción para tu etiqueta...',
    labelCreated: 'Etiqueta creada con éxito',
    labelsUpdated: 'Etiquetas actualizadas con éxito',
    labelUpdated: 'Etiqueta actualizada con éxito',
    labelDeleted: 'Etiqueta eliminada con éxito',
    labelSlogan:
      '¡Optimiza la organización de tus conversaciones con una nueva etiqueta personalizada!. Crear una etiqueta específica para ti y tu equipo es una manera fantástica de mantener tus conversaciones ordenadas y accesibles',
    limitFreeMessages: 'Límite de mensajes gratuitos',
    newBooking: 'Nueva reserva',
    needHelp: '¿Necesitas ayuda?',
    noConversationForThisFilter: 'No hay conversaciones para este filtro',
    noConversationForThisSearch: 'No hay conversaciones para esta búsqueda',
    noResultForThisSearch: 'No hay resultados para esta búsqueda',
    noAgentCreated: 'Para generar una respuesta inteligente primero debes crear un usuario BOT',
    new_incoming_message: 'Nuevo mensaje entrante',
    generateInteligentResponse: 'Respuesta inteligente',
    rewriteAnswer: 'Mejorar respuesta',
    orderBy: 'Ordenar por',
    order: 'Orden',
    markedAsImportant: 'Marcado como importante',
    markedAsNotImportant: 'Marcado como no importante',
    maxCharacters: 'Máximo de caracteres',
    limitReached:
      'Ha alcanzado el límite máximo de caracteres, puede separar el mensaje en mensajes más cortos',
    maxCharactersDescription: 'Máximo de caracteres permitidos:',
    youNotHavePermissionToAccess: '¡Conecta los canales de comunicación y genera más reservas!',
    youNotHavePermissionToAccessDescription:
      'Reúnete con uno de nuestros profesionales para que pueda ayudarte y mostrarte todo el poder de Pxsol, ¡gratis!',
    yourTeamDonthaveChannelActive: 'Tu equipo no tiene canales activos',
    yourTeamDonthaveChatContracts: 'Tu equipo no tiene contratos de chat activos',
    noContactsForThisSearch:
      'El contacto que busca no aparece entre las sugerencias. Completar datos del contacto manualmente.',
    noBookings: 'No hay reservas',
    resultOfSearch: 'Resultado de la búsqueda',
    searchBooking: 'Buscar reserva',
    searchContact: 'Buscar contacto',
    searchByParticipantOrContact: 'Email - nombre - teléfono',
    incomming:
      '¡Estamos trabajando en ello! Este apartado estará disponible muy pronto. Gracias por tu paciencia.',
    slectConversation: 'Selecciona una conversación para comenzar a chatear',
    slogan:
      'Envía y recibe mensajes desde un solo lugar para darle escala y automatización a la gestión de tu hotel.',
    successToChangueNameOfGroup: 'Nombre del grupo cambiado con éxito',
    successToClosedFragment: 'El fragmento se cerró con éxito',
    successToDeleteParticipant: 'Participante eliminado con éxito',
    successToOutGroup: 'Saliste del grupo',
    successToUpdateNotification: 'Notificación actualizada con éxito',
    selectContactToAssignAsLead: 'Selecciona un contacto para asignar como responsable',
    selectEmbedding: 'Seleccionar base de conocimientos',
    labelResponse:
      '| Ej: Sí, el hotel ofrece varias actividades para niños: contamos con actividades especiales para niños durante los fines de semana.',
    labelTask: '| Ej: ¿El hotel ofrece actividades para niños?',
    successToChangeStatus: 'Estado cambiado con éxito',
    successToMarkConversationAsNotRead: 'Has marcado la conversación como no leída.',
    successToAssociateBooking: 'Reserva asociada con éxito',
    welcome: 'Bienvenido a Pxsol Conversaciones',
    successToChangeNameOfGroup: 'Nombre del grupo cambiado con éxito',
    toCreateGropuDefineAName: 'Para crear un grupo define un nombre',
    noIsPosibleUnassigneeUser: 'No es posible desasignar el usuario',
    fragmentClosedManually: 'Fragmento cerrado manualmente',
    fragmentClosedManuallySubtitle:
      'El fragmento se cerró manualmente, puedes reabrir la conversación enviando una plantilla de primer contacto.',
    fragmentFailed: 'Fragmento fallido',
    fragmentFailedSubtitle:
      'El fragmento falló, la conversación no se pudo inciar con el contacto proporcionado. Intente editar el número de teléfono (esto creará otra conversación) o seleccionar otra plantilla. Asegurese que el contacto tenga whatsapp activo y que el número sea correcto.',
    fragmetNew: 'Fragmento nuevo',
    fragmetNewSubtitle:
      'Conversación creada con éxito, puedes enviar una plantilla de primer contacto.',
    successToCreateContact: 'Contacto creado con éxito',
    goToMessage: 'Ir al mensaje',
    goToConversation: 'Ir a la conversación',
    fragmetExpired: 'Fragmento expirado.',
    fragmetExpiredSubtitle:
      'Han pasado 24 horas desde el último mensaje de este contacto. El fragmento se cerró automáticamente, podes reabrir la conversación enviando una plantilla de primer contacto.',
    fragmentPending: 'Fragmento pendiente',
    fragmentPendingSubtitle:
      'Plantilla enviada, esperando respuesta del cliente. Podrá cerrar el fragmento pasadas las 24 horas desde el envío de la plantilla.',
    resumeConversation: 'Para continuar con la converación envía una plantilla.',
    messagesContacts: 'Se muestran los usuarios de',
    inviteNewUsers: 'Para invitar nuevos o modificarlos, haz click ',
    successToMoveCoversationOfTeam: 'Conversación movida de equipo con éxito',
    waitMovingConversation: 'Moviendo conversación de equipo...',
    automaticResponse: 'Respuesta automática',
    successAddedToEmbedding: 'Añadido a la base de conocimientos',
    successToUpdateStatus: 'Estado actualizado con éxito',
    summarizeAnswer: 'Acortar mensaje',
    reviewIfYuoHaveTemplatesIntTheLanguageOfTheConversation:
      'Revisa si tienes plantillas en el idioma de la conversación',
    currentTeamNotHaveAChannelAvaliable:
      'El equipo actual no tiene un canal activo para iniciar una conversación.',
    currentTeamHaveAvaliableChannel:
      'El equipo actual tiene un canal activo para iniciar una conversación',
    messageInfoAssignee: 'Solo se puede asignar un responsable a la conversación.',
    theBotIsAssignedDefault:
      'El bot es el encargado por defecto de la conversacion, si no hay persona asignada.',
    teamsAvaliables: 'Equipos disponibles',
    toCreateConversationSelectChannel:
      'Para iniciar conversación con este contacto, primero debes seleccionar el canal de comunicación.',
    restrictedAttachment:
      'Por favor, ten presente que al seleccionar una plantilla con archivos adjuntos para enviar mediante Instagram o Facebook, únicamente se podrán enviar imágenes. Esto se debe a las restricciones impuestas por Meta respecto a los tipos de archivos admitidos. Los adjuntos que no sean imágenes serán enviados como un enlace al final de la plantilla.',
    webCheckIn:
      'Para completar el web check in deberá ingresar con su apellido y número de Reserva en el siguiente link:',
    pressHereToCloseRightBar: 'Presiona aquí para cerrar la barra lateral derecha',
    upsUpdate: '¡Ups! Parece que necesitas una actualización',
    freeLimiRreached:
      'Has alcanzado el límite de mensajes gratuitos generados por la IA, para seguir utilizando esta funcionalidad, actualiza tu plan.',
    upsUpdateDescription:
      '¡Hola! Para acceder a las integraciones con Instagram, Facebook, WhatsApp y activar nuestro avanzado Bot con IA, necesitas actualizar tu plan.'
  },
  min: 'min',
  minors: 'Menores',
  moreInfo: 'Más información',
  motor: 'Motor',
  moveTo: 'Mover a',
  selectTeamToMoveConversation: 'Selecciona un equipo para mover la conversación',
  markNotRead: 'Marcar como no leído',
  name: 'Nombre',
  nameOfGroup: 'Nombre del grupo',
  navigation: {
    automatizations: 'Automatizaciones',
    chat: 'Chat',
    clients: 'Clientes',
    channels: 'Canales',
    home: 'Inicio',
    post: 'Publicar',
    profile: 'Perfil',
    settings: 'Ajustes',
    notifications: 'Notificaciones',
    customization: 'Personalización',
    help: 'Ayuda',
    todos: 'Todos',
    sin_asignar: 'Sin asignar',
    asignados_a_mi: 'Asignados a mi',
    cerrados: 'Cerrados',
    grupos: 'Grupos',
    expired: 'Vencidos',
    settingsChangelog: 'Registro de cambios',
    addEmbedding: 'Añadir a la base de conocimientos',
    newAutomatizationTemplate: 'Nueva automatización de plantilla',
    newAutomatizationBot: 'Nueva automatización de bot',
    newAutomatizationRange: 'Nuevo rango de días y horarios',
    dinamicContent: 'Contenido dinámico',
    welcome: 'Bienvenido'
  },
  new: 'Nuevo',
  newConversation: 'Nueva conversación',
  newconversation: 'Nueva conversación',
  newgroup: 'Nuevo grupo',
  newGroup: 'Nuevo grupo',
  newChat: 'Nuevo chat',
  newVersionAvaliable:
    'Nueva versión disponible, por favor actualiza la aplicación para disfrutar de las mejoras',
  next: 'Siguiente',
  nights: 'Noches',
  no: 'No',
  noAssignee: 'Sin responsable',
  noInternet: 'No hay conexión a Internet',
  noMessages: 'No hay mensajes',
  noConversations: 'No hay conversaciones',
  noContent: 'No hay contenido',
  noMinors: 'No hay menores',
  noPhones: 'Este contacto no tiene teléfonos, edita el contacto para agregar uno',
  noEmails: 'Este contacto no tiene emails, edita el contacto para agregar uno',
  noTemplatesInThisLanguage: 'No hay plantillas en este idioma',
  notifications: 'Notificaciones',
  notification: {
    channels: 'Notificación de canales',
    sound: 'Sonido de notificaciones',
    conversation: 'Notificación de conversaciones',
    assigned: 'Tema de notificaciones',
    assignedToMe: 'Notificarme solo las conversaciones asignadas a mí',
    unassigned: 'Conversaciones sin asignar',
    assignedTooltip: 'Activa o desactiva las notificaciones de asignación',
    browser: 'Notificaciones del navegador',
    disbleNotifiacions: 'Desactivar notificaciones',
    enableNotifiacions: 'Activar notificaciones',
    muteConversation: 'Silenciar conversación',
    unmuteConversation: 'Desactivar silencio',
    disabledNotificationsConversation: 'Notificaciones de la conversación desactivadas',
    soundActivedToAllMessages: 'Sonido de todas las notificaciones',
    soundDisabledToAllMessages: 'Sonido de todas las notificaciones desactivado',
    soundTooltip: 'Activa o desactiva el sonido de todas las notificaciones',
    channelsTooltip: 'Activa o desactiva las notificaciones de los canales que tengas activos',
    browserTooltip: 'Activa o desactiva las notificaciones del navegador',
    browserSubtitle:
      'Activa las notificaciones del navegador para recibir alertas de nuevos mensajes'
  },
  noTags: 'No hay etiquetas',
  noResults: 'No hay resultados',
  noTemplatesAvailable: 'No hay plantillas disponibles',
  NotificationOfAllMessages: 'Notificaciones de todos los mensajes',
  notificationsNotAllowedByBrowser: 'Las notificaciones no están permitidas por el navegador',
  Messages: 'Mensajes',
  open: 'Abierto',
  objectsRelations: 'Objetos relacionados',
  objectAssociations: 'Asociaciones de objetos',
  pagos: 'Pagos',
  permissionCamera:
    'Lo sentimos, necesitamos permisos de cámara y galería para que esta funcionalidad funcione correctamente.',
  person: 'Persona',
  pending: 'Pendiente',
  pickDateFromCalendar: 'Selecciona una fecha del calendario',
  phone: 'Teléfono',
  plantillas: 'Plantillas',
  pleaseWait: 'Por favor espere... ',
  pointofSale: 'Punto de venta',
  published: 'Publicado',
  recordAudio: 'Grabar audio',
  resolved: 'Resuelto',
  rooms: 'Habitaciones',
  retry: 'Reintentar',
  retryLogin:
    'Ha ocurrido un error al iniciar sesión, por favor, intenta nuevamente en breve, y si el mismo persiste, contacta a Soporte de Pxsol en: WHATSAPP_DE_SOPORTE. Lamentamos los inconvenientes ocasionados. Código de error: MENSAJE_DE_ERROR_ORIGINAL',
  errorCode: 'MENSAJE_DE_ERROR_ORIGINAL',
  save: 'Guardar',
  search: 'Buscar',
  searchContact: 'Buscar contacto',
  searchBooking: 'Buscar reserva',
  selectConversation: 'Selecciona una conversación',
  selectDate: 'Selecciona una fecha',
  selectSingle: 'Selecciona una fecha',
  selectMultiple: 'Seleccionar fechas',
  selectRange: 'Seleccionar rango',
  selectDateCheckInAndCheckOut: 'Selecciona una fecha de llegada y salida',
  sendMessageTo: 'Enviar mensaje a',
  sendEmailTo: 'Enviar email a',
  stopRecordAudio: 'Detener grabación de audio',
  skip: 'Omitir',
  success: 'Éxito',
  status: 'Estado',
  successBooking: 'Reserva creada con éxito',
  tags: 'Etiquetas',
  time: 'Tiempo',
  team: 'Equipo',
  textToRewrite: 'Texto a reescribir',
  textRewrited: 'Texto reescrito',
  typeInDate: 'Escribe una fecha',
  tradename: 'Nombre comercial',
  trigger: 'Desencadenador',
  placeholders: {
    textAreaRewrite: 'Ej: Redacta esto como si fuese un email...',
    textAreaCompose: 'Ej: Responde al usuario de manera amable que no tenemos disponibilidad...'
  },
  question: 'Pregunta',
  updating: 'Actualizando mensajes...',
  userData:
    'Inicia sesión en la ventana emergente, si ya lo hiciste, aguarda un momento, si has cerrado la ventana o tienes problemas, oprime nuevamente el botón de Iniciar Sesión e intenta de nuevo',
  view: 'Ver',
  viewBooking: 'Ver reserva',
  welcome: 'Bienvenido',
  whatsapp: 'Whatsapp',
  instagram: 'Instagram',
  facebook: 'Facebook',
  writeMessage:
    'Shift + Enter para nueva línea. Escribe / para seleccionar una plantilla o contenido dinámico',
  yes: 'Si',
  youAreSure: '¿Estás seguro?',
  youAreSureAssosiateThisBooking: '¿Estás seguro de asociar esta reserva?',
  youAreSureAssiciateThisContact: '¿Estás seguro de asociar este contacto?',
  labelAssociateBooking: 'La reserva que estas a punto de asociar es la siguiente:',
  labelAssociateContact: 'El contacto que estas a punto de asociar es el siguiente:',
  goNextIntroSlider: 'Continuar',
  goPrevIntroSlider: 'Volver',
  skipIntroSlider: 'Omitir',
  doneIntroSlider: '¡Entendido!',
  responseGenerated: 'Respuesta generada',
  scheduleDemo: 'Agendar Demo Gratuita',
  selectContactToInitInternalConversation:
    'Selecciona un contacto para iniciar una conversación interna',
  statusMessages: {
    info: 'Información',
    success: 'Éxito',
    warning: 'Advertencia',
    error: 'Error',
    open: 'Abierto',
    closed: 'Cerrado',
    expired: 'Expirado',
    bug: 'Bug',
    added: 'Agregado',
    changed: 'Cambiado',
    removed: 'Removido',
    deprecated: 'Deprecado',
    fixed: 'Fixed',
    security: 'Seguridad',
    pending: 'Pendiente',
    delivered: 'Entregado',
    read: 'Leído',
    send: 'Enviar',
    new: 'Nuevo',
    failed: 'Fallido',
    rejected: 'Rechazado'
  },
  soon: 'Próximamente',
  soundNotifications: 'Sonido de notificaciones',
  soundOfAllMessages: 'Sonido de todas las notificaciones',
  selectChannelToInitConversaton: 'Selecciona un canal para iniciar una conversación',
  helpGuidesLink: 'Guías',
  viewGuides: 'Ver guías',
  viewInfo: 'Ver información',
  writeAnInstruction: 'Escribe una instrucción',
  isConnectedwhatsappNumber: 'Tu número de WhatsApp está conectado',
  isConnectedfacebookNumber: 'Tu página de Facebook está conectada',
  isConnectedinstagramNumber: 'Tu cuenta de Instagram está conectada',
  isConnectwhatsappDialogTitle: 'Tu número de WhatsApp está conectado',
  isConnectwhatsappDialogDescription:
    '¡Excelente! Ya puedes comenzar a gestionar tu WhatsApp desde Pxsol Conversaciones',
  connectwhatsappNumber: 'Conecta tu número de WhatsApp',
  connectwhatsappNumberDialogTitle: 'Conecta tu número de WhatsApp',
  connectwhatsappNumberDialogDescription:
    'Agenda una breve reunión con tu ejecutivo para que puedas conectar tu número y comenzar a gestionar tu WhatsApp desde Pxsol Conversaciones',
  connectwhatsappDialogTitle: 'Conecta tu número de WhatsApp',
  connectwhatsappDialogDescription:
    'Necesitamos algunos datos para enviar tu solicitud de conexión a uno de nuestros Ejecutivos de cuenta. Completa el formulario a continuación para continuar con la integración.',
  connectfacebookDialogTitle: 'Conecta tu página de Facebook',
  connectfacebookDialogDescription:
    'Necesitamos algunos datos para enviar tu solicitud de conexión a uno de nuestros Ejecutivos de cuenta. Completa el formulario a continuación para continuar con la integración.',
  connectfacebookNumber: 'Conecta tu página de Facebook',
  connectinstagramDialogTitle: 'Conecta tu cuenta de Instagram',
  connectinstagramDialogDescription:
    'Necesitamos algunos datos para enviar tu solicitud de conexión a uno de nuestros Ejecutivos de cuenta. Completa el formulario a continuación para continuar con la integración.',
  connectinstagramNumber: 'Conecta tu cuenta de Instagram',
  connectwidgetDialogTitle: 'Conecta tu widget',
  connectwidgetDialogDescription:
    'Necesitamos algunos datos para enviar tu solicitud de conexión a uno de nuestros Ejecutivos de cuenta. Completa el formulario a continuación para continuar con la integración.',
  isConnectinstagramDialogDescription:
    '¡Excelente! Ya puedes comenzar a gestionar tus mensajes de Instagram',
  isConnectinstagramDialogTitle: 'Tu cuenta de Instagram está conectada',
  isConnectfacebookDialogDescription:
    '¡Excelente! Ya puedes comenzar a gestionar tus mensajes de Facebook',
  isConnectfacebookDialogTitle: 'Tu página de Facebook está conectada',
  isConnectwidgetDialogDescription:
    '¡Excelente! Ya puedes comenzar a gestionar tus mensajes de tu widget',
  isConnectwidgetDialogTitle: 'Tu widget está conectado',
  warning: 'Atención',
  waiting: 'Esperando...',
  daily_limit_reached:
    'Se ha alcanzado el límite de solicitudes de AI para el día de hoy, por favor, intente nuevamente más tarde'
};
export default resource;

export type ILocalization = typeof resource;
