import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getBookingsOfApi2, getRankerConversations } from '@/redux/slices/conversation/requests';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import ContactCard from '@/atoms/ContactCard';
import ViewPhonesContactCard from '@/atoms/ViewPhonesContactCard';
import ViewEmailsContactCard from '@/atoms/ViewEmailsContactCard';
import MainCta from '@/atoms/MainCta';
import { getDisplayName, openURL } from '@/utils/functions';
import { getContactsApi2 } from '@/redux/slices/contacts/requests';
import translate from '@/helpers/traslations';
import { URL_PMS_EDIT_CONTACT } from '@/utils/constants';
import CustomTooltip from '@/components/tooltip/CustomTooltip';

import { RootState } from '@/redux/store';
import { Mangify, Pencil } from '@/assets/icons/Icons';
import InputComponent from '@/components/conversation/footer/InputComponent';
import { Spinner } from '@gluestack-ui/themed';
import RankedContacts from '@/components/RankedContacts';
import EmptySearchContact from '@/components/emptystates/EmptySearchContact';
import { StyleSheet, VirtualizedList } from 'react-native';
import PopoverChannels from '@/components/partials/newConverExternal/PopoverChannels';
import { PlatformsEnum } from '@/types/enums/conversation';
import { InputSlot } from '@gluestack-ui/themed';
import { THView, TText, TView, TVView } from '@/components/themed';

function NewConversationExternal(props) {
  const dispatch = useAppDispatch();
  const company_id = useAppSelector((state: RootState) => state.user.company.IDEmpresas);

  const { navigation } = props;
  const teamId = useMemo(() => props?.route?.route?.params?.team, [props]);

  const [contacts, setContacts] = useState<any>([]);
  const [searching, setSearching] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [gettingContacts, setGettingContacts] = useState(false);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [rankedContacts, setRankedContacts] = useState([]);
  const [search, setSearch] = useState([]);
  const [channelWhatsapp, setChannelWhatsapp] = useState<any>([]);
  const activeChannels = useAppSelector(
    (state: RootState) => state.user.activeChannels?.[company_id]
  );

  const activeChannelsTeam = useMemo(() => {
    if (!teamId || !activeChannels) return [];
    return activeChannels?.filter((channel) => channel?.conection_status === 'enable');
  }, [teamId, activeChannels]);
  console.log({ activeChannelsTeam });

  useEffect(() => {
    let channelWhatsapp = [];

    activeChannelsTeam?.forEach((channel) => {
      if (channel?.channel_type === PlatformsEnum?.whatsapp) {
        channelWhatsapp.push({
          key: channel?.id,
          label: channel?.channel_data,
          value: channel?.channel_id,
          icon: channel.channel_type,
          name: channel?.channel_label
        });
      }
    });

    setChannelWhatsapp(channelWhatsapp);
  }, [activeChannelsTeam]);

  let debounceTimer: any = null;

  useEffect(() => {
    setGettingContacts(true);
    dispatch(getContactsApi2({ page: 1 }))
      .then((res: any) => {
        setContacts(res?.contacts);
        setGettingContacts(false);
      })
      .catch((err) => {
        console.log('err', err);
        setGettingContacts(false);
      });
    dispatch(getRankerConversations())
      .then((res: any) => {
        setRankedContacts(res?.data);
      })
      .catch((err) => {
        console.log('err getRankerConversations', err);
      });
  }, []);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: '100%',
          gap: 5,
          paddingVertical: 16,
          height: '95%',
          justifyContent: 'space-between'
        },
        input: {
          width: '100%',
          paddingHorizontal: 10,
          borderRadius: 20,
          justifyContent: 'space-between'
          // backgroundColor: colors.backgroundSecondary
        }
      }),
    []
  );

  const onSearch = (text: string) => {
    clearTimeout(debounceTimer);
    // Establecer un nuevo temporizador
    debounceTimer = setTimeout(() => {
      if (text.length > 2) {
        setSearching(true);
        dispatch(
          getBookingsOfApi2({
            company: `${company_id}`,
            index: 'contacts_v2',
            query: `(${text}*) | ("${text}")`
          })
        ).then((res: any) => {
          if (res?.results?.hits?.hits?.length > 0) {
            // setContacts(res?.results?.hits?.hits);
            const results = res?.results?.hits?.hits?.map((item) => ({
              id: item?._source?.id,
              name: item?._source?.name,
              last_name: item?._source?.last_name,
              phones: item?._source?.phones,
              emails: item?._source?.emails,
              avatar: item?._source?.avatar
            }));
            setSearch(results);
            setSearching(false);
            setSearchEmpty(false);
          } else {
            setSearch([]);
            setSearchEmpty(true);
            setSearching(false);
          }
        });
      } else {
        setSearch([]);
        setSearchEmpty(false);
      }
    }, 800);
  };

  const getMore = () => {
    if (contacts?.current_page === contacts?.last_page) return;
    setLoadingMore(true);
    dispatch(getContactsApi2({ page: contacts?.current_page + 1 }))
      .then((res: any) => {
        const { data, ...resto } = res?.contacts;
        setLoadingMore(false);
        setContacts((prev) => ({
          data: [...prev?.data, ...data],
          ...resto
        }));
      })
      .catch((err) => {
        setLoadingMore(false);
        console.log('err', err);
      });
  };

  const handlePressNewContact = (id) => {
    openURL(URL_PMS_EDIT_CONTACT + id);
    props?.navigation?.goBack();
  };

  const renderItem = ({ item }) => {
    return (
      <ContactCard
        variant="accent"
        key={JSON.stringify(item)}
        name={getDisplayName(item)}
        avatar={item?.avatar}
        middle={
          <CustomTooltip title={translate(`editContact`)}>
            <MainCta
              onPress={() => handlePressNewContact(item?.id)}
              icon={Pencil}
              buttonSize="xs"
              iconSize="xs"
              variant="outline"
            />
          </CustomTooltip>
        }
        emails={<ViewEmailsContactCard user={item} />}
        phones={<ViewPhonesContactCard showToast={props?.showToast} team={teamId} user={item} />}
      />
    );
  };

  const ListHeaderComponent = useCallback(() => {
    return (
      <TView style={{ paddingVertical: 8 }}>
        {searchEmpty && <EmptySearchContact />}
        {searching && <Spinner color="$pxsol" size="small" />}
        {rankedContacts?.length > 0 && search?.length === 0 && !searchEmpty && (
          <RankedContacts
            showToast={props?.showToast}
            route={props?.route?.route}
            navigation={navigation}
            rankedContacts={rankedContacts}
          />
        )}
        {!searchEmpty && !searching && (
          <TText>
            {search?.length > 0
              ? `${search?.length} ${translate('messages.resultOfSearch')}`
              : translate('contactos')}
          </TText>
        )}
      </TView>
    );
  }, [search, searchEmpty, searching, rankedContacts]);

  return (
    <TVView style={styles.container}>
      <THView style={[styles.input]}>
        <InputComponent
          background="background"
          onChange={onSearch}
          placeholder={translate('search')}
          // value={handleChange}
          multiline={false}
          autofocus={false}
          leftComponent={
            <InputSlot flexDirection="row" gap="$1">
              <MainCta iconColor="$iconos" icon={Mangify} iconSize="md" variant="outline" />
            </InputSlot>
          }
        />
      </THView>
      {/* test comment */}
      <THView>
        <PopoverChannels channelWhatsapp={channelWhatsapp} />
      </THView>
      <TView style={{ width: '100%', flex: 1, marginBottom: 15 }}>
        <VirtualizedList
          data={search?.length > 0 || searchEmpty ? search : (contacts?.data ?? [])}
          initialNumToRender={10}
          renderItem={renderItem}
          onEndReached={!loadingMore && search?.length === 0 ? getMore : null}
          keyExtractor={(item: any) => item?.id}
          getItemCount={(data) => data?.length}
          getItem={(data, index) => data[index]}
          ListFooterComponent={loadingMore ? <Spinner color="$pxsol" size="small" /> : null}
          getItemLayout={(data, index) => ({
            length: 135,
            offset: 135 * index,
            index
          })}
          windowSize={10}
          ListEmptyComponent={gettingContacts && <Spinner color="$pxsol" size="small" />}
          ListHeaderComponent={ListHeaderComponent}
        />
      </TView>
    </TVView>
  );
}

export default React.memo(NewConversationExternal);
