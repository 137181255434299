import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, TextInputContentSizeChangeEventData } from 'react-native';
import { InputField, Input } from '@gluestack-ui/themed';
import { RootState, useAppDispatch, useAppSelector } from '@/redux/store';

import MainCta from '@/atoms/MainCta';
import DialogComponent from '@/components/DialogComponent';
import { verifyIsNotNativeDevice } from '@/utils/functions';
import { InputComponentProps } from './interfaces.d';
import {
  KEY_OMIT_HIGILIGHT,
  LIMIT_OF_CHARACTERS,
  MAX_HEIGHT_INPUT,
  MIN_INPUT_HEIGHT
} from '@/utils/constants';
import translate from '@/helpers/traslations';
import { setLastInputKeyPress } from '../../../redux/slices/theme';
import { TextInputKeyPressEventData } from 'react-native';
import { NativeSyntheticEvent } from 'react-native';
import { THView, TText } from '@/components/themed';
import colors from '../../../../config/colors';

function InputComponent({
  onChange,
  placeholder,
  textInputRef,
  handlepressEnter,
  value,
  autofocus,
  disabled = false,
  multiline = true,
  background,
  magicLoading,
  inputHeight,
  setHeight = null,
  sx,
  onSelecionChange = null,
  onFocus = null,
  lastKeyPress = null,
  leftComponent = null,
  rightComponent = null,
  inputMode = 'text'
}: InputComponentProps) {
  const dispatch = useAppDispatch();
  const [dialogMaxLegngth, setDialogMaxLength] = useState(false);
  const themeMode = useAppSelector((state: RootState) => state.settings.themeMode);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          borderRadius: 8,
          height: inputHeight ? inputHeight + 18 : MIN_INPUT_HEIGHT,
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        },
        input: {
          flex: 1,
          height: '100%',
          marginLeft: 10,
          maxHeight: MAX_HEIGHT_INPUT,
          borderRadius: 8,
          color: '$text',
          borderColor: '$transparent'
        }
      }),
    [inputHeight]
  );

  const veryfyMaxLength = (e: any) => {
    if (e.nativeEvent.text.length >= LIMIT_OF_CHARACTERS) {
      setDialogMaxLength(true);
    } else {
      onChange(e.nativeEvent.text);
    }
  };

  const sendToEnter = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData & { timeStamp: number }>) => {
      if (e.nativeEvent.key === 'Enter' && lastKeyPress?.split('-')[0] === 'Shift') {
        return;
      } else {
        const keyUniqueEvent = `${e.nativeEvent.key}-${e.nativeEvent.timeStamp}`;
        dispatch(setLastInputKeyPress(keyUniqueEvent));
      }
      if (KEY_OMIT_HIGILIGHT.includes(e.nativeEvent.key)) {
        e.preventDefault();
      }
      if (['ArrowDown', 'ArrowUp', 'Enter', 'Escape', 'Shift'].includes(e.nativeEvent.key)) {
        const keyUniqueEvent = `${e.nativeEvent.key}-${e.nativeEvent.timeStamp}`;
        dispatch(setLastInputKeyPress(keyUniqueEvent));
      }

      if (e.nativeEvent.key === 'Enter' && handlepressEnter) {
        handlepressEnter(e);
      }
    },
    [lastKeyPress, handlepressEnter]
  );

  const handleContentSizeChange = (
    event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
  ) => {
    const newHeight = event.nativeEvent.contentSize.height;
    const heightDifference = Math.abs(newHeight - (inputHeight || 0));
    console.log({ heightDifference, newHeight, inputHeight });

    if (heightDifference > 10 && newHeight < MAX_HEIGHT_INPUT && value?.length > 0) {
      setHeight?.(newHeight);
      return;
    }
    if (value?.length === 0) {
      setHeight?.(MIN_INPUT_HEIGHT);
      return;
    }
    if (newHeight > MAX_HEIGHT_INPUT) {
      setHeight?.(MAX_HEIGHT_INPUT);
      return;
    }
  };

  return (
    <THView bgColor={background ?? 'background'} style={styles.container}>
      <Input
        sx={sx}
        paddingVertical="$2"
        style={styles.input}
        variant="outline"
        w="$full"
        borderColor="$transparent"
        alignItems={setHeight ? 'flex-end' : 'center'}
      >
        {leftComponent && leftComponent}
        <InputField
          ref={textInputRef}
          autoCorrect
          maxLength={LIMIT_OF_CHARACTERS}
          editable={!disabled}
          autoFocus={autofocus}
          onChange={(e) => veryfyMaxLength(e)}
          value={value}
          fontSize="$md"
          multiline={multiline}
          onFocus={onFocus}
          onKeyPress={sendToEnter}
          onEndEditing={(event) => console.log('onEndEditing', event)}
          onSubmitEditing={(event) => console.log('onSubmitEditing', event)}
          inputMode={inputMode}
          allowFontScaling
          onSelectionChange={onSelecionChange && onSelecionChange}
          onContentSizeChange={handleContentSizeChange}
          height="$full"
          color={colors[themeMode]?.text}
          // minHeight={inputHeight ? MIN_INPUT_HEIGHT : 40}
          alignItems="center"
          display="flex"
          placeholder={
            magicLoading
              ? 'Estamos generando una respuesta, aguarda un momento, por favor...'
              : placeholder
          }
        />
        {rightComponent && rightComponent}
      </Input>

      <DialogComponent
        dialogVisible={dialogMaxLegngth}
        setDialogVisible={setDialogMaxLength}
        buttonCancel={false}
        children={
          <TText>{`${translate('messages.maxCharactersDescription')} ${LIMIT_OF_CHARACTERS}`}</TText>
        }
        idStringTitle="messages.maxCharacters"
        idStringDescription="messages.limitReached"
        Cta={
          <MainCta action="secondary" labelId="accept" onPress={() => setDialogMaxLength(false)} />
        }
      />
    </THView>
  );
}

export default React.memo(InputComponent);
