import React from 'react';
import { RootState, useAppSelector } from '@/redux/store';
import ContainerLastMessage from '@/atoms/ContainerLastMessage';
import { getColor, getIcons } from '@/utils/functions';
import MessageRecivedRender from '@/atoms/MessageRecivedRender';
import { PropsOriginMessage } from './interfaces';

const OriginMessage = ({ email, lastMessage, conversationId }: PropsOriginMessage) => {
  const userEmail = useAppSelector((state: RootState) => state.user.user?.email);
  const conversation = useAppSelector(
    (state: RootState) => state.conversations.conversations?.[conversationId]
  );

  return email == userEmail ? (
    <ContainerLastMessage
      messages={lastMessage}
      icon={lastMessage?.status === 'open' ? getIcons('read') : getIcons(lastMessage?.status)}
      color={`${getColor(lastMessage?.status)}`}
    />
  ) : (
    <MessageRecivedRender isGroup={conversation?.group?.status} messages={lastMessage} />
  );
};

export default React.memo(OriginMessage);
