import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { StyleSheet } from 'react-native';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';

import { filtersToCheckOutConver } from '@/utils/filters';

import { InConverStackRoutes, Routes } from '../../../routers/routes';
import { RootStackParams } from '../../../routers/interfaces';
import { RootState, useAppDispatch, useAppSelector } from '../../../redux/store';
import { Participant } from '@/redux/slices/participants/interfaces';
import { outConversationToFilter, setIdConversation } from '@/redux/actionTypes';
import AvatarConversationCard from '../../partials/conversationCard.tsx/AvatarConversationCard';
import { IS_WEB_PC } from '@/utils/functions';
import { THView, TPressable, TView } from '@/components/themed';
import RightComponentCard from './RightComponentCard';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { TABLET_SCREEN } from '@/utils/globales';

const ConversationCard = memo(
  ({ conversationId }: { conversationId: string }) => {
    const disptach = useAppDispatch();
    const navigation = useNavigation<StackNavigationProp<RootStackParams>>();
    const route = useRoute<RouteProp<RootStackParams>>();
    const menuRigth = useAppSelector((state: RootState) => state.settings.menuRigth);
    const conversation_id = useAppSelector(
      (state: RootState) => state.conversations.id_conversation
    );
    const participants = useAppSelector((state: RootState) => state.participants.participants);
    const conversation = useAppSelector(
      (state: RootState) => state.conversations.conversations[conversationId]
    );
    const userId = useAppSelector((state: RootState) => state.user.user.id);
    const outConversationToFilterQueue = useAppSelector(
      (state: RootState) => state.conversations.outConversationToFilterQueue
    );
    const [picture, setPicture] = useState<string | undefined>('');
    const [contacto, setContacto] = useState<string | undefined>('');

    const filter = useMemo(() => {
      return route?.params?.filter;
    }, [route?.params?.filter]);

    const current = useMemo(() => {
      return conversationId === conversation_id;
    }, [conversationId, conversation_id]);

    const styles = useMemo(
      () =>
        StyleSheet.create({
          container: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            minHeight: 50,
            gap: 8,
            width: '100%',
            flex: 1,
            flexGrow: 1,
            marginHorizontal: 'auto',
            padding: 2,
            paddingBottom: 8,
            borderRadius: current ? 10 : 0,
            borderBottomWidth: 1
          },
          pressable: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderRadius: 8,
            paddingHorizontal: 2,
            overflow: 'hidden'
          },
          avatarContainer: {
            width: '10%',
            minWidth: 32
          }
        }),
      [current]
    );

    const [isPc] = useMediaQuery([
      {
        minWidth: TABLET_SCREEN
      }
    ]);

    useEffect(() => {
      if (conversation?.group?.status && conversation?.group?.name?.length !== 0) {
        setContacto(conversation?.group.name);
        setPicture(conversation?.group.avatar);
      } else {
        participants[conversationId]?.forEach((participant: Participant) => {
          // cast participant.id and user.id to strings
          const participantId = participant?.userId?.id_user?.toString();
          const Id = userId?.toString();
          if (participantId !== Id) {
            setContacto(participant?.userId?.name);
            setPicture(participant?.userId?.avatar);
            // setIdContactos(participant.id);
          }
        });
      }
    }, [contacto, picture, conversation?.group?.name, participants[conversationId]]);

    const onPressCard = useCallback(() => {
      if (
        Object.keys(outConversationToFilterQueue).length > 0 &&
        filtersToCheckOutConver?.includes(filter)
      ) {
        Object.keys(outConversationToFilterQueue).forEach((key: string) => {
          disptach(outConversationToFilter(outConversationToFilterQueue[key]));
        });
      }
      disptach(setIdConversation(conversation?._id));
      navigation.setParams({
        id: conversationId
      });
      if (isPc) {
        navigation.navigate(Routes.Conversation, {
          id: conversationId,
          filter: filter
        });
        if (menuRigth) {
          navigation.navigate(InConverStackRoutes.InfoConversation, {
            id: conversationId,
            fragmentId: conversation?.fragments?.[0]
          });
        }
      } else {
        navigation.navigate(Routes.ConversationStack, {
          id: conversationId,
          filter: filter
        });
      }
    }, [conversationId, filter, outConversationToFilterQueue, isPc, menuRigth]);

    return (
      <TPressable style={styles.pressable} onPress={onPressCard}>
        <THView
          bgColor={
            IS_WEB_PC ? (current ? 'backgroundActive' : 'transparent') : 'backgroundSecondary'
          }
          border="border"
          style={[styles.container]}
        >
          <TView style={styles.avatarContainer}>
            <AvatarConversationCard
              status={conversation?.group?.status}
              avatar={conversation?.group?.avatar}
              name={conversation?.group?.name}
              platform={conversation?.platform}
              contacto={contacto}
              picture={picture}
            />
          </TView>
          <RightComponentCard conversationId={conversationId} />
        </THView>
      </TPressable>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.conversationId === nextProps.conversationId;
  }
);

export default ConversationCard;
